import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 1080.000000 902.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g transform="translate(0.000000,902.000000) scale(0.100000,-0.100000)"
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd">
<path d="M0 4510 l0 -4510 5400 0 5400 0 0 4510 0 4510 -5400 0 -5400 0 0
-4510z m5270 4441 c0 -23 -23 -28 -188 -37 -143 -8 -172 -21 -172 -79 0 -55
31 -69 140 -63 88 5 90 5 90 -17 0 -21 -6 -23 -87 -29 -100 -8 -122 -20 -129
-67 -5 -28 -1 -39 19 -58 l26 -23 144 11 c190 14 187 15 187 -8 0 -25 -18 -29
-202 -37 l-157 -7 -30 32 c-38 41 -46 78 -26 127 14 37 14 42 -5 79 -24 47
-25 76 -5 115 23 44 62 59 182 68 188 16 213 15 213 -7z m130 -102 c0 -138 11
-174 68 -217 33 -25 43 -27 166 -32 123 -5 131 -6 131 -25 0 -18 -8 -20 -80
-23 -196 -7 -278 33 -325 162 -7 18 -8 70 -4 142 6 102 9 114 25 114 17 0 19
-10 19 -121z m-630 67 c0 -22 -6 -25 -81 -37 -52 -8 -83 -18 -87 -28 -3 -8 6
-89 21 -180 15 -96 22 -170 17 -175 -25 -25 -37 7 -59 155 -33 224 -23 202
-95 195 -33 -4 -76 -9 -94 -13 -31 -5 -33 -4 -30 18 3 22 12 25 198 55 107 17
198 32 203 33 4 0 7 -10 7 -23z m1405 0 c91 -11 100 -14 97 -31 -2 -11 -5 -21
-7 -23 -2 -2 -61 4 -132 12 -71 9 -145 16 -164 16 -43 0 -72 -28 -72 -69 0
-47 25 -62 121 -75 66 -9 88 -15 90 -28 5 -24 -5 -26 -96 -17 -95 10 -113 4
-132 -41 -10 -25 -10 -34 4 -55 10 -15 23 -28 31 -31 7 -3 82 -13 166 -22 144
-16 154 -18 151 -37 -2 -11 -5 -21 -7 -23 -6 -7 -304 32 -332 43 -61 25 -82
126 -37 176 15 17 16 24 6 53 -25 70 -6 131 50 160 35 17 52 17 263 -8z m448
-87 c90 -18 107 -25 107 -40 0 -25 -24 -24 -151 3 -87 18 -106 19 -138 8 -130
-45 -152 -209 -40 -292 15 -11 74 -29 139 -42 113 -22 127 -29 111 -54 -6 -10
-28 -9 -112 8 -130 27 -179 48 -211 91 -86 116 -48 267 82 323 48 21 87 20
213 -5z m-2570 -78 c9 -38 -5 -111 -29 -145 -21 -30 -18 -42 15 -55 56 -24
133 -124 115 -152 -12 -21 -42 -9 -49 20 -12 54 -81 101 -150 101 -53 0 -195
-48 -195 -66 0 -8 9 -44 21 -80 11 -35 18 -71 15 -79 -8 -21 -34 -19 -40 3 -3
9 -28 92 -55 183 -56 182 -58 194 -36 203 18 6 25 -8 50 -93 10 -34 25 -65 32
-68 22 -9 180 46 212 73 37 31 55 91 46 152 -6 41 -5 43 16 40 16 -2 25 -13
32 -37z m2921 -23 c112 -34 176 -176 127 -283 -61 -135 -235 -166 -337 -60
-113 117 -63 297 96 346 38 11 68 10 114 -3z m-3441 -122 c53 -22 71 -50 71
-110 1 -47 5 -57 28 -76 26 -20 61 -86 74 -136 4 -15 0 -23 -14 -26 -15 -4
-24 6 -46 57 -37 86 -59 95 -151 60 -38 -14 -75 -23 -82 -21 -30 12 -10 35 53
60 84 34 96 46 92 93 -2 29 -9 41 -30 53 -25 13 -32 13 -80 -4 -29 -10 -78
-29 -108 -43 -30 -14 -65 -29 -77 -34 -13 -5 -23 -15 -23 -23 0 -8 29 -84 65
-170 36 -86 63 -161 60 -166 -13 -21 -37 -8 -51 28 -9 20 -40 96 -71 167 -77
182 -76 177 -11 203 29 11 87 35 128 52 127 54 130 55 173 36z m3869 -45 c34
-34 43 -39 72 -34 57 9 118 -34 131 -94 5 -25 -4 -56 -43 -150 -27 -65 -55
-133 -62 -150 -12 -31 -42 -45 -53 -25 -3 4 22 70 54 147 33 77 59 150 59 163
0 51 -78 83 -113 44 -8 -9 -29 -47 -46 -84 -23 -53 -34 -66 -46 -61 -19 7 -17
21 16 109 20 52 21 66 11 88 -16 35 -56 50 -90 34 -26 -13 -34 -28 -117 -228
-30 -72 -47 -92 -66 -73 -7 7 9 54 45 140 31 71 56 134 56 140 0 6 16 25 35
42 53 47 105 44 157 -8z m-4307 -207 c72 -30 115 -100 115 -187 0 -176 -194
-268 -337 -160 -79 60 -97 191 -37 275 61 87 159 114 259 72z m5085 -180 c57
-34 105 -65 107 -70 1 -5 -1 -15 -6 -23 -8 -12 -29 -3 -110 47 -119 73 -159
83 -223 54 -55 -24 -89 -70 -96 -127 -10 -91 20 -131 149 -206 49 -29 92 -56
95 -61 4 -6 0 -16 -7 -23 -12 -12 -31 -3 -122 52 -113 68 -138 95 -158 166
-24 89 24 190 111 235 75 39 135 29 260 -44z m-5451 -109 c49 -73 92 -143 95
-153 19 -58 -52 -142 -119 -142 -27 0 -35 -5 -45 -31 -16 -37 -63 -69 -103
-69 -51 0 -82 27 -148 127 -35 54 -66 100 -70 103 -3 3 -14 20 -25 38 -16 29
-17 34 -4 42 8 5 18 10 21 10 3 0 40 -54 83 -119 102 -158 119 -171 174 -144
47 23 44 67 -9 151 -32 51 -38 66 -27 73 21 14 22 12 64 -48 52 -75 61 -83 95
-83 35 0 69 31 69 63 0 13 -37 79 -81 148 -45 68 -84 130 -87 137 -4 11 12 31
26 32 1 0 42 -61 91 -135z m1591 -25 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3
6 -1 10 4 10 6 0 11 -4 11 -10z m4109 -51 c100 -23 157 -106 149 -218 -15
-205 -291 -261 -383 -77 -29 57 -33 125 -11 177 19 46 75 97 122 114 47 17 66
18 123 4z m-4444 -39 c-11 -5 -27 -9 -35 -9 -9 0 -8 4 5 9 11 5 27 9 35 9 9 0
8 -4 -5 -9z m-154 -56 c-19 -8 -39 -13 -45 -10 -14 5 32 25 59 25 13 0 9 -5
-14 -15z m-1602 -8 c9 -10 -2 -24 -54 -67 -36 -30 -65 -59 -65 -66 0 -10 104
-138 150 -183 58 -57 81 -92 69 -106 -6 -8 -14 -14 -18 -14 -4 0 -58 59 -120
130 -112 129 -136 146 -161 115 -7 -9 -34 -33 -60 -55 -43 -36 -47 -37 -57
-20 -10 15 1 29 89 106 56 49 122 108 147 131 50 46 62 50 80 29z m1456 -46
c-3 -5 -12 -10 -18 -10 -7 0 -31 -9 -52 -20 -22 -11 -89 -43 -149 -71 -119
-56 -257 -133 -384 -215 -45 -30 -85 -54 -87 -54 -8 0 -183 -128 -250 -182
-33 -27 -89 -72 -125 -101 -81 -66 -363 -344 -421 -417 -25 -30 -54 -66 -66
-80 -63 -74 -182 -234 -253 -340 -61 -92 -110 -169 -110 -175 0 -2 -18 -34
-40 -71 -48 -78 -140 -257 -140 -273 0 -6 -4 -11 -10 -11 -18 0 -11 17 55 145
112 215 295 513 349 568 9 8 16 18 16 22 0 3 26 36 58 74 31 37 75 89 96 117
22 27 123 133 225 235 170 170 341 317 496 427 93 66 254 172 261 172 4 0 39
19 78 43 91 55 271 146 361 182 39 16 74 32 80 37 13 11 37 10 30 -2z m5116
-136 c19 -9 37 -31 49 -58 15 -36 24 -45 53 -50 42 -8 73 -33 87 -72 21 -54 4
-91 -89 -198 -117 -134 -127 -142 -146 -126 -13 11 -2 27 78 119 51 58 101
118 110 132 29 44 -4 99 -59 99 -27 0 -41 -10 -87 -61 -38 -43 -60 -60 -71
-56 -25 10 -20 21 34 84 63 74 68 123 15 150 -41 21 -76 -4 -179 -123 -93
-109 -117 -127 -124 -92 -3 18 174 224 215 250 35 22 75 23 114 2z m-6953
-146 c7 -10 -16 -41 -90 -118 -55 -58 -106 -115 -115 -127 -32 -47 17 -113 73
-99 14 4 53 35 85 68 56 57 61 60 76 45 16 -15 12 -21 -45 -81 -47 -48 -62
-71 -62 -93 0 -34 8 -46 37 -62 40 -21 70 -1 174 110 96 103 139 132 139 93 0
-8 -44 -60 -97 -116 -54 -55 -100 -103 -103 -107 -10 -16 -50 -31 -82 -31 -51
0 -95 32 -109 80 -10 34 -18 42 -48 50 -20 6 -43 16 -52 23 -40 31 -52 104
-23 147 20 30 216 230 225 230 4 0 11 -6 17 -12z m7380 -267 c116 -136 127
-156 112 -211 -14 -52 -54 -80 -114 -80 -52 0 -64 10 -176 143 -35 42 -69 77
-75 77 -5 0 -39 -25 -75 -55 -58 -50 -66 -54 -80 -39 -14 14 -10 19 35 59 214
185 261 225 266 225 3 0 51 -54 107 -119z m-7677 -144 c36 -28 93 -73 128
-100 34 -26 66 -60 72 -75 15 -39 2 -91 -31 -124 -23 -22 -37 -28 -73 -28 -47
0 -81 18 -169 92 -63 52 -101 65 -132 44 -31 -20 -41 -50 -26 -84 6 -16 61
-65 130 -117 115 -86 136 -110 106 -121 -19 -7 -265 182 -277 213 -19 51 -11
93 25 129 26 26 42 34 71 34 45 0 59 -7 164 -87 46 -34 94 -63 106 -63 31 0
65 36 65 68 0 43 3 40 -190 185 -36 27 -65 53 -64 58 3 25 5 29 17 29 6 0 42
-24 78 -53z m7936 -231 c120 -56 156 -194 80 -307 -32 -47 -217 -172 -234
-157 -22 18 -15 27 57 74 39 25 70 51 70 58 0 21 -162 256 -176 256 -8 0 -43
-20 -78 -45 -36 -25 -67 -45 -70 -45 -3 0 -10 8 -15 18 -9 17 3 28 90 86 139
95 184 105 276 62z m-837 -66 c0 -5 -4 -10 -9 -10 -6 0 -13 5 -16 10 -3 6 1
10 9 10 9 0 16 -4 16 -10z m-369 -43 c-8 -8 -11 -7 -11 4 0 9 3 19 7 23 4 4 9
2 11 -4 3 -7 -1 -17 -7 -23z m-7092 -65 c-13 -20 -33 -53 -46 -72 -13 -19 -23
-39 -23 -45 0 -5 68 -52 150 -104 141 -88 169 -114 137 -127 -12 -4 -245 132
-284 166 -29 24 -52 5 -103 -87 -24 -44 -38 -51 -57 -32 -10 10 2 33 184 322
20 33 26 36 44 27 20 -11 20 -11 -2 -48z m7258 17 c-2 -22 -37 -49 -37 -28 0
11 28 49 36 49 1 0 2 -9 1 -21z m-47 -84 c0 -8 -4 -15 -9 -15 -13 0 -22 16
-14 24 11 11 23 6 23 -9z m70 -91 c0 -8 -5 -12 -10 -9 -6 4 -8 11 -5 16 9 14
15 11 15 -7z m-5900 -22 c0 -5 -9 -17 -20 -27 -20 -18 -25 -11 -14 19 6 17 34
23 34 8z m7202 -29 c29 -26 41 -78 28 -118 -13 -39 -45 -66 -135 -115 -72 -39
-115 -77 -115 -102 0 -10 7 -30 16 -43 32 -49 73 -36 297 97 56 33 54 33 66 9
10 -17 -4 -28 -136 -101 -133 -75 -149 -82 -187 -77 -58 8 -96 52 -96 111 0
59 21 83 130 145 108 63 120 74 120 111 0 30 -39 70 -68 70 -8 0 -75 -34 -149
-76 -126 -71 -163 -83 -163 -51 0 12 249 157 290 169 21 7 82 -11 102 -29z
m-975 5 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-174 -58 c7 -1
6 -6 -4 -13 -9 -7 -15 -26 -14 -49 2 -41 -1 -44 -26 -25 -17 13 -23 67 -8 67
5 0 9 7 9 16 0 11 5 14 16 10 9 -3 21 -6 27 -6z m177 -19 c0 -5 -9 -7 -20 -4
-17 4 -20 0 -20 -24 0 -18 -10 -41 -26 -59 -16 -17 -24 -22 -21 -11 4 10 1 17
-7 17 -26 0 -25 37 0 49 13 6 24 15 24 20 0 10 21 18 53 20 9 0 17 -3 17 -8z
m154 -5 c-6 -16 -111 -28 -134 -15 -13 7 -13 8 0 13 8 3 40 7 70 9 30 2 58 4
62 5 4 1 5 -4 2 -12z m-6494 -57 c0 -13 -58 -69 -71 -69 -5 0 -9 -5 -9 -12 0
-19 -31 -48 -52 -48 -25 0 -23 19 5 29 12 5 33 21 46 35 14 14 30 26 37 26 6
0 15 11 18 25 6 25 26 35 26 14z m2283 -43 c-16 -22 -33 -26 -33 -8 0 6 23 20
48 31 2 0 -5 -10 -15 -23z m3902 11 c8 -9 10 -19 4 -28 -7 -11 -9 -11 -9 4 0
9 -4 17 -10 17 -5 0 -10 5 -10 10 0 14 9 13 25 -3z m-4141 -28 c-9 -11 -19
-17 -21 -15 -8 7 21 45 30 39 4 -3 1 -14 -9 -24z m51 21 c3 -5 2 -10 -4 -10
-5 0 -13 5 -16 10 -3 6 -2 10 4 10 5 0 13 -4 16 -10z m-3619 -14 c3 -8 -17
-59 -45 -115 -57 -113 -63 -151 -31 -217 38 -77 53 -67 115 70 20 43 36 66 47
66 32 0 29 -19 -17 -109 -25 -50 -45 -95 -45 -101 0 -5 36 -28 80 -50 58 -29
78 -44 74 -55 -9 -23 -29 -19 -138 34 -141 69 -177 114 -177 219 -1 49 7 73
52 165 49 100 72 126 85 93z m3678 6 c3 -5 19 -9 36 -10 36 -1 70 -11 70 -20
-1 -38 -53 -37 -64 1 -4 14 -9 18 -14 10 -5 -8 -15 -8 -40 2 -17 8 -32 17 -32
20 0 8 38 6 44 -3z m4331 -2 c-3 -5 -1 -10 5 -10 6 0 8 -4 5 -10 -3 -5 -19
-10 -34 -10 -53 0 -76 -20 -89 -75 -7 -28 -12 -63 -12 -79 0 -24 2 -27 15 -16
22 18 20 -8 -2 -32 -22 -24 -33 -23 -34 5 0 12 -3 16 -6 9 -4 -10 -8 -10 -21
0 -12 10 -16 10 -19 1 -5 -15 -19 -2 -40 35 -21 36 -15 55 30 99 23 23 52 53
64 67 19 23 29 26 83 26 38 0 59 -4 55 -10z m78 3 c-13 -2 -33 -2 -45 0 -13 2
-3 4 22 4 25 0 35 -2 23 -4z m-4085 -28 c-3 -3 -9 2 -12 12 -6 14 -5 15 5 6 7
-7 10 -15 7 -18z m3762 10 c0 -7 -39 -45 -45 -45 -9 0 29 49 38 50 4 0 7 -2 7
-5z m-4238 -24 c-8 -4 -12 -10 -10 -13 1 -3 -55 -66 -126 -141 -71 -75 -135
-146 -143 -157 -8 -11 -20 -17 -26 -14 -7 4 -9 4 -5 -1 4 -4 3 -14 -3 -21 -6
-8 -9 -20 -6 -27 6 -16 169 -72 190 -64 8 3 39 41 67 84 57 84 191 260 233
306 25 26 47 35 47 20 0 -9 -28 -52 -48 -74 -38 -42 -242 -343 -242 -356 0 -8
57 -24 206 -60 20 -4 32 15 111 174 49 98 106 204 126 236 l37 57 -22 9 c-13
5 -47 12 -76 16 -33 4 -52 11 -49 19 6 19 47 19 70 -1 11 -10 17 -12 12 -4 -5
8 8 5 33 -9 23 -13 42 -28 42 -34 0 -5 3 -7 6 -4 3 4 0 17 -7 29 -12 23 -12
23 15 6 18 -12 25 -14 24 -5 -2 7 -7 16 -13 20 -5 4 -7 10 -3 13 10 10 37 -25
32 -40 -7 -17 11 -21 21 -5 3 6 12 10 18 10 7 0 6 -4 -3 -11 -11 -7 -4 -8 25
-4 33 5 37 4 21 -6 -10 -6 -37 -9 -62 -6 -36 4 -47 1 -58 -14 -7 -10 -16 -16
-21 -11 -5 4 -5 2 -1 -5 3 -6 -23 -71 -60 -144 -87 -175 -137 -291 -127 -300
13 -11 245 -41 253 -32 7 9 24 65 70 238 16 61 39 133 50 160 l21 50 -5 -45
c-5 -44 -5 -44 7 -13 14 36 33 59 24 28 -17 -56 -101 -384 -105 -407 -4 -25
-1 -27 39 -33 24 -3 94 -7 155 -8 107 -2 111 -3 122 -26 7 -14 12 -36 11 -50
0 -24 -1 -24 -9 -6 -8 18 -9 17 -9 -7 -1 -16 4 -28 10 -28 13 0 1 -133 -12
-138 -5 -2 -9 -25 -8 -50 1 -39 2 -43 9 -22 7 22 8 21 9 -12 1 -46 -25 -54
-32 -10 -2 15 -3 -8 -2 -51 1 -44 -2 -72 -7 -65 -9 15 -8 197 1 203 4 2 5 50
4 107 l-1 103 -54 7 c-46 6 -202 12 -234 9 -12 -1 -34 -85 -79 -301 -14 -69
-33 -158 -41 -198 -9 -40 -14 -77 -12 -84 4 -10 17 -12 193 -27 l90 -8 -110
-3 c-97 -2 -104 -3 -57 -10 88 -13 71 -20 -35 -13 l-99 6 -13 -59 c-6 -32 -16
-72 -21 -87 -5 -16 -6 -36 -3 -45 4 -15 3 -15 -6 -2 -6 8 -11 29 -12 45 -1 25
-3 22 -10 -14 -5 -24 -11 -46 -14 -49 -9 -10 -5 32 14 120 19 94 16 108 -28
108 -35 0 -205 29 -271 46 -30 7 -57 10 -61 6 -4 -4 -17 -32 -29 -62 -23 -58
-37 -80 -51 -80 -5 0 -9 9 -9 20 0 21 20 28 21 8 0 -7 5 -2 10 11 5 13 8 29 5
35 -2 6 -5 3 -5 -6 -1 -10 -5 -18 -11 -18 -13 0 -13 10 5 59 11 32 12 43 2 46
-7 2 -39 11 -72 20 -33 9 -95 28 -137 42 -69 22 -78 27 -78 49 0 27 3 28 28
14 26 -14 191 -59 242 -66 l45 -6 44 124 c24 67 71 192 104 276 33 84 58 154
55 157 -6 6 -199 57 -222 59 -13 1 -33 -23 -68 -79 -70 -111 -159 -267 -200
-347 -33 -66 -110 -140 -123 -118 -3 5 -13 6 -23 3 -14 -5 -13 -2 3 11 19 17
19 17 -10 11 -22 -4 -26 -3 -15 5 22 15 50 12 50 -5 0 -22 13 -18 34 10 10 13
23 22 29 18 6 -4 8 0 4 11 -7 18 82 198 189 380 35 60 64 112 64 116 0 4 -46
26 -102 48 -114 46 -102 48 -159 -28 -14 -19 -40 -50 -58 -69 -17 -18 -31 -36
-31 -40 0 -10 -55 -71 -63 -71 -13 0 -7 15 16 40 13 14 32 40 42 58 11 17 25
32 32 32 7 0 13 7 13 15 0 8 5 15 11 15 5 0 8 4 5 8 -3 5 9 25 25 46 17 20 29
42 26 49 -3 7 -26 23 -51 37 -55 28 -66 40 -36 40 12 0 19 -4 16 -9 -3 -5 1
-11 9 -15 8 -3 17 -1 20 4 4 6 1 10 -6 10 -7 0 -10 3 -7 6 4 3 16 0 28 -6 15
-8 19 -15 11 -19 -6 -4 6 -7 27 -7 35 1 44 8 103 75 35 40 99 108 143 150 43
42 92 91 109 109 17 17 35 32 41 32 6 0 5 -4 -2 -9z m4433 -62 c0 -34 4 -44
18 -47 12 -2 17 -13 17 -35 1 -30 1 -31 15 -13 14 19 15 19 15 -8 0 -18 5 -26
13 -23 6 2 12 8 12 13 0 5 10 22 22 38 16 20 28 26 43 21 13 -4 20 -2 20 7 -1
18 -29 66 -30 50 0 -7 -5 -10 -10 -7 -6 4 -1 14 12 25 21 17 25 17 41 3 9 -9
17 -20 17 -25 0 -5 11 -25 24 -44 13 -19 22 -46 20 -59 -2 -19 2 -25 17 -25
23 0 25 -18 4 -26 -8 -4 -25 -24 -37 -45 -17 -31 -27 -39 -50 -39 -38 0 -45
23 -17 61 l23 32 -22 -5 c-37 -10 -25 19 13 32 19 6 33 16 30 21 -3 5 -14 3
-25 -3 -15 -10 -22 -10 -32 0 -9 9 -17 3 -35 -28 -13 -22 -30 -40 -37 -40 -14
0 -26 -21 -26 -46 0 -8 6 -14 14 -14 8 0 16 -7 19 -15 4 -9 19 -14 39 -13 18
1 44 0 57 0 23 -2 24 -3 6 -22 -21 -23 -50 -27 -42 -6 5 11 0 12 -24 3 -16 -6
-29 -15 -29 -19 0 -18 -31 -6 -40 15 -8 21 -9 21 -9 -5 -1 -18 5 -29 18 -33
23 -7 28 -20 35 -87 6 -51 -3 -60 -31 -33 -19 20 -30 11 -13 -10 18 -21 63
-19 80 5 8 11 22 20 32 20 10 0 18 5 18 10 0 6 8 10 18 10 14 0 18 8 17 32 0
20 8 41 23 57 25 27 26 27 57 -31 20 -36 19 -51 -5 -66 -17 -10 -18 -15 -8
-31 6 -11 18 -17 26 -13 11 4 13 -1 8 -19 l-7 -24 20 23 c11 13 25 21 31 17 6
-3 7 0 4 9 -9 23 23 31 45 12 10 -9 25 -16 34 -16 9 0 17 -4 17 -10 0 -5 -7
-7 -15 -4 -24 10 -17 -4 20 -40 39 -38 45 -57 15 -49 -11 3 -20 12 -20 19 0 8
-7 14 -15 14 -8 0 -15 -4 -15 -10 0 -5 4 -10 9 -10 5 0 11 -14 13 -30 4 -29 3
-31 -26 -27 -29 3 -31 7 -38 51 -4 26 -10 51 -13 54 -4 4 -4 -15 0 -43 4 -35
3 -46 -4 -35 -5 8 -14 11 -18 6 -4 -4 0 -13 10 -20 9 -7 18 -26 18 -42 1 -24
3 -25 6 -6 2 12 8 22 13 22 20 0 40 -13 40 -27 0 -8 6 -13 12 -11 14 5 32 -9
41 -32 4 -12 6 -11 6 3 1 24 31 22 31 -3 0 -11 6 -19 12 -17 7 1 14 -8 16 -20
2 -13 8 -23 14 -23 6 0 6 -6 -2 -15 -19 -23 -52 -19 -48 5 2 10 -1 18 -7 17
-11 -2 -11 -30 0 -63 4 -14 2 -23 -8 -27 -10 -4 -13 0 -11 11 3 9 0 31 -5 47
-7 24 -9 25 -10 8 -1 -12 -7 -24 -13 -26 -8 -3 -5 -6 5 -6 12 -1 17 -7 15 -18
-2 -10 -11 -17 -21 -17 -9 1 -14 -2 -12 -7 3 -4 -3 -13 -13 -18 -14 -7 -18
-18 -15 -36 3 -15 1 -25 -6 -25 -6 0 -11 8 -11 18 0 10 -3 22 -7 25 -8 9 -33
-12 -33 -27 0 -6 4 -5 9 3 6 9 9 5 9 -13 0 -30 -12 -41 -30 -30 -7 5 -35 8
-63 9 -44 1 -47 0 -22 -9 38 -14 34 -32 -10 -40 -65 -10 -73 -8 -73 18 0 14
-4 27 -9 30 -5 3 -11 20 -14 38 -5 37 -17 71 -60 176 -16 40 -27 75 -24 78 3
4 0 13 -8 20 -7 8 -11 23 -8 34 5 17 11 19 39 14 22 -4 34 -2 34 5 0 10 -34
16 -78 14 -8 -1 -31 38 -53 85 -22 48 -52 111 -66 140 -14 29 -23 55 -20 58 3
4 20 -1 38 -10 35 -18 49 -14 49 15 0 21 -10 25 -27 8 -11 -11 -83 -2 -83 10
0 3 23 21 51 39 54 36 65 52 54 80 -18 41 -34 112 -35 150 0 23 4 32 13 29 7
-2 12 -22 12 -48z m-3828 15 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2
13 -5z m-940 -39 c-23 -22 -33 -18 -12 5 10 11 20 18 23 16 2 -3 -3 -12 -11
-21z m920 19 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m4938
-121 c3 -10 3 -19 -2 -20 -69 -24 -119 -107 -108 -178 13 -90 80 -139 184
-133 56 3 66 0 69 -14 8 -39 -104 -51 -173 -19 -22 10 -55 33 -72 51 l-33 34
-81 -34 c-57 -23 -83 -30 -91 -22 -20 20 -1 37 76 67 l76 30 0 55 c0 68 25
125 74 168 41 35 70 41 81 15z m-1162 -67 c12 2 15 1 7 -2 -8 -4 -21 -11 -27
-17 -10 -7 -13 -3 -12 14 0 13 3 19 5 13 3 -7 15 -10 27 -8z m-3506 -63 c-3
-10 -5 -4 -5 12 0 17 2 24 5 18 2 -7 2 -21 0 -30z m3920 5 c4 -7 6 -25 4 -39
-2 -21 2 -27 16 -25 12 1 18 -6 19 -22 1 -13 10 -30 21 -37 22 -15 37 -55 22
-55 -5 0 -9 9 -9 20 0 19 -14 25 -47 21 -7 -1 -13 8 -13 20 0 18 -2 19 -15 9
-9 -7 -17 -11 -18 -9 -27 38 -37 63 -31 77 4 9 8 22 9 27 1 6 3 13 3 18 3 12
30 8 39 -5z m-113 -53 c-4 -8 -10 -15 -14 -15 -5 0 -17 -3 -27 -6 -10 -3 -4 3
12 15 36 25 36 25 29 6z m-3819 -83 c-11 -44 -17 -15 -9 40 8 47 9 49 12 19 2
-19 1 -45 -3 -59z m3735 68 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10
4 10 6 0 11 -4 11 -10z m-3703 -22 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4
-12 1 -19z m-4530 -42 c48 -19 130 -50 181 -68 78 -28 92 -36 90 -53 -2 -11
-10 -19 -18 -17 -8 1 -95 32 -192 69 -144 53 -178 69 -178 84 0 26 15 24 117
-15z m8283 24 c0 -5 -11 -10 -25 -10 -14 0 -25 5 -25 10 0 6 11 10 25 10 14 0
25 -4 25 -10z m-70 -27 c0 -20 -3 -24 -11 -16 -7 7 -9 19 -6 27 10 24 17 19
17 -11z m-6980 -45 c0 -15 -38 -60 -43 -51 -8 13 22 75 33 68 5 -3 10 -11 10
-17z m3295 -89 c6 -24 11 -26 48 -24 26 1 29 1 10 -2 -34 -4 -47 -23 -16 -23
9 0 14 -2 11 -5 -14 -15 -57 9 -67 36 -5 16 -14 29 -20 29 -6 0 -9 20 -8 44 1
24 5 42 10 39 4 -2 8 2 8 9 1 7 5 -7 10 -32 4 -25 11 -57 14 -71z m3685 91 c0
-5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m-7478 -93
c-32 -70 -89 -217 -122 -317 -33 -99 -58 -143 -46 -82 3 19 12 48 20 65 8 18
29 73 46 122 48 139 114 292 118 272 2 -10 -5 -37 -16 -60z m-744 24 c207 -64
217 -71 217 -151 0 -41 -5 -54 -32 -82 -28 -30 -31 -38 -26 -83 6 -66 -20
-110 -74 -124 -34 -9 -57 -5 -186 33 -80 25 -151 47 -157 51 -12 8 -13 32 -1
39 5 3 72 -15 150 -39 79 -25 153 -45 166 -45 26 0 65 42 65 70 0 36 -36 65
-109 85 -53 14 -71 24 -71 36 0 28 13 29 81 9 35 -11 74 -20 87 -20 31 0 62
34 62 68 0 50 -17 59 -242 127 -71 22 -88 31 -88 46 0 26 13 25 158 -20z
m1202 -17 c0 -13 6 -15 27 -9 24 6 25 5 13 -10 -7 -9 -26 -23 -42 -31 -15 -8
-27 -18 -26 -22 0 -4 -2 -16 -6 -27 -45 -117 -60 -152 -83 -188 -14 -23 -26
-43 -25 -44 8 -11 -2 -36 -19 -52 -13 -12 -23 -37 -26 -65 -3 -25 -12 -51 -19
-57 -8 -6 -14 -19 -14 -28 -1 -17 -23 -51 -34 -51 -13 0 -5 64 13 94 28 47 34
83 15 94 -14 8 -14 10 0 21 9 8 16 20 16 27 0 8 5 14 10 14 6 0 10 7 10 15 0
13 20 65 41 107 5 10 9 26 9 35 0 10 9 29 20 43 11 14 20 33 20 42 0 10 14 30
30 45 17 14 30 32 30 39 0 11 14 21 33 23 4 1 7 -6 7 -15z m195 -64 c-3 -5
-11 -10 -16 -10 -6 0 -19 -10 -29 -22 l-19 -23 6 23 c3 13 15 28 27 32 29 12
38 12 31 0z m7230 -39 c7 -12 -12 -24 -25 -16 -11 7 -4 25 10 25 5 0 11 -4 15
-9z m-5545 -11 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10
-4 10 -10z m-32 -49 c-7 -9 -13 -18 -15 -20 -1 -2 -1 5 0 16 0 11 7 20 14 20
10 0 10 -4 1 -16z m-1638 -50 c0 -12 -20 -25 -27 -18 -7 7 6 27 18 27 5 0 9
-4 9 -9z m7254 -96 c3 -9 0 -15 -9 -15 -8 0 -15 -4 -15 -8 0 -9 -70 -42 -88
-42 -7 0 -12 5 -12 11 0 6 9 8 23 4 15 -4 18 -3 8 3 -9 7 -10 12 -2 16 6 4 11
2 11 -4 0 -6 10 -4 25 6 14 9 25 23 25 30 0 19 26 18 34 -1z m-7288 -12 c13
-17 13 -17 14 0 0 10 5 15 10 12 18 -11 11 -31 -15 -43 -14 -6 -25 -16 -25
-22 0 -7 -7 -18 -15 -26 -13 -14 -15 -11 -10 21 5 45 14 75 22 75 3 0 12 -8
19 -17z m7318 -19 c24 -9 19 -43 -6 -54 -23 -9 -23 -9 2 -10 24 -1 24 -1 5
-15 -25 -19 -38 -15 -46 16 -3 13 -11 23 -17 22 -18 -4 -14 8 10 28 23 21 29
22 52 13z m-7525 -8 c-12 -10 -79 -16 -79 -6 0 5 19 10 42 10 23 0 39 -2 37
-4z m7433 -43 c-6 -2 -10 -9 -6 -14 3 -5 -5 -9 -17 -9 -22 0 -22 0 -3 15 10 8
24 14 29 14 6 0 4 -3 -3 -6z m38 -8 c0 -8 -7 -15 -16 -15 -14 0 -14 3 -4 15 7
8 14 15 16 15 2 0 4 -7 4 -15z m-90 -35 c0 -5 -9 -10 -20 -10 -11 0 -20 5 -20
10 0 6 9 10 20 10 11 0 20 -4 20 -10z m-8800 -11 c0 -7 -14 -81 -31 -166 l-32
-154 23 -24 c25 -26 59 -32 90 -15 22 12 33 45 56 160 19 98 32 123 76 146 59
30 130 0 158 -67 10 -24 7 -54 -21 -189 -28 -137 -35 -160 -51 -160 -24 0 -23
18 7 170 13 69 25 137 25 152 0 52 -79 78 -115 38 -11 -12 -27 -65 -41 -131
-24 -115 -37 -140 -88 -166 -66 -34 -156 23 -156 100 0 32 42 265 55 305 5 15
45 16 45 1z m3335 -7 c-3 -3 -11 0 -18 7 -9 10 -8 11 6 5 10 -3 15 -9 12 -12z
m-1725 -16 c0 -8 -10 -23 -23 -33 -13 -10 -22 -24 -19 -32 3 -10 -1 -12 -16
-9 -17 5 -22 1 -25 -26 -4 -31 -4 -30 -5 5 -2 28 5 44 31 72 33 38 57 47 57
23z m-160 -17 c0 -5 -3 -9 -8 -9 -4 0 -15 -12 -25 -27 l-18 -28 5 26 c5 21 1
28 -14 32 -36 9 -21 23 20 19 22 -2 40 -8 40 -13z m2060 10 c0 -6 -7 -9 -15
-5 -10 3 -15 -1 -15 -12 0 -9 -7 -25 -14 -35 -12 -16 -14 -16 -20 -1 -9 23 -7
31 4 14 8 -12 10 -9 10 12 0 14 6 29 13 31 21 9 37 7 37 -4z m-2705 -36 c-3
-21 -10 -42 -15 -46 -4 -5 -3 12 3 37 12 55 22 62 12 9z m7325 -53 c-12 -23
-69 -52 -83 -43 -5 3 5 13 22 24 16 10 38 30 48 46 14 21 19 23 21 11 2 -10
-2 -27 -8 -38z m-4698 8 c-7 -7 -12 -8 -12 -2 0 14 12 26 19 19 2 -3 -1 -11
-7 -17z m1285 -60 c-2 -13 -4 -3 -4 22 0 25 2 35 4 23 2 -13 2 -33 0 -45z m40
40 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-3462 -8 c-3 -5 -11
-10 -16 -10 -6 0 -7 5 -4 10 3 6 11 10 16 10 6 0 7 -4 4 -10z m-55 -15 c0 -8
-6 -15 -14 -15 -8 0 -17 -6 -19 -12 -4 -9 -6 -8 -6 3 -1 16 17 39 31 39 4 0 8
-7 8 -15z m6773 -16 c-5 -5 -37 -12 -69 -15 -32 -3 -75 -9 -94 -14 -52 -13
-160 -21 -160 -11 0 8 39 15 155 26 52 6 162 20 175 24 2 0 -1 -4 -7 -10z
m-6573 -28 c0 -5 3 -17 7 -27 4 -12 3 -15 -6 -10 -6 4 -18 2 -26 -4 -16 -14
-21 -35 -6 -25 5 3 -1 -14 -13 -37 -25 -47 -54 -66 -42 -27 5 13 14 44 21 69
7 27 19 46 29 48 9 2 16 8 16 13 0 5 5 9 10 9 6 0 10 -4 10 -9z m-683 -33 c-3
-8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m433 -4 c0 -15 4 -23 10 -19
6 4 8 16 5 28 -5 17 -4 19 4 7 22 -31 12 -67 -33 -117 l-44 -48 -12 26 c-11
23 -9 32 14 71 14 25 23 47 20 51 -9 9 5 27 22 27 8 0 14 -10 14 -26z m365 7
c8 -14 -26 -94 -35 -84 -20 21 -8 93 16 93 7 0 15 -4 19 -9z m6045 0 c-18 -10
-282 -48 -400 -57 -99 -8 -102 5 -3 17 38 4 129 17 203 27 149 22 224 26 200
13z m-2953 -7 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-102
-14 c3 -5 -3 -10 -15 -10 -12 0 -18 5 -15 10 3 6 10 10 15 10 5 0 12 -4 15
-10z m-3080 -20 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4
11 -10z m-711 -15 c-4 -8 -8 -15 -10 -15 -2 0 -4 7 -4 15 0 8 4 15 10 15 5 0
7 -7 4 -15z m6276 -15 c0 -5 -9 -10 -21 -10 -11 0 -17 5 -14 10 3 6 13 10 21
10 8 0 14 -4 14 -10z m60 6 c0 -2 -9 -6 -20 -9 -11 -3 -20 -1 -20 4 0 5 9 9
20 9 11 0 20 -2 20 -4z m2320 -16 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6
5 10 10 10 6 0 10 -4 10 -10z m-8679 -60 c-7 -36 -9 -39 -10 -15 -1 33 11 85
16 70 1 -6 -1 -30 -6 -55z m6189 50 c-20 -13 -63 -13 -55 0 3 6 21 10 38 10
24 0 28 -3 17 -10z m1080 0 c-8 -5 -31 -9 -50 -9 -32 1 -33 2 -10 9 14 4 36 8
50 9 20 1 22 -1 10 -9z m-4233 -57 c-13 -30 -16 -26 -10 16 4 21 8 28 13 20 5
-6 3 -23 -3 -36z m2973 32 c-35 -12 -55 -11 -46 4 3 4 22 8 43 8 l38 1 -35
-13z m1101 -8 c-10 -9 -11 -8 -5 6 3 10 9 15 12 12 3 -3 0 -11 -7 -18z m-1245
-6 c-7 -10 -73 -22 -261 -46 -146 -19 -156 -20 -325 -46 -74 -11 -164 -23
-199 -25 -35 -3 -75 -10 -88 -15 -32 -12 -73 -12 -73 1 0 12 28 18 100 23 30
2 89 9 130 15 41 6 163 23 270 37 107 14 209 28 225 31 48 8 199 31 213 33 7
0 11 -3 8 -8z m-2809 -33 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z
m3073 7 c-7 -8 -19 -15 -27 -15 -13 0 -13 3 -3 15 7 8 19 15 27 15 13 0 13 -3
3 -15z m-5710 -19 c0 -8 -7 -16 -15 -20 -17 -6 -18 -4 -9 18 8 20 24 21 24 2z
m46 -12 c-5 -13 0 -15 27 -9 29 5 29 4 -11 -15 -30 -15 -42 -26 -38 -36 3 -8
0 -17 -7 -20 -6 -2 -13 -21 -14 -41 -1 -21 -7 -39 -12 -41 -6 -2 -11 -9 -11
-16 0 -24 46 -5 64 25 19 32 66 42 66 14 0 -8 -5 -15 -11 -15 -6 0 -8 -9 -4
-22 11 -35 23 -44 32 -22 9 24 28 44 43 44 15 0 30 -44 23 -70 -6 -22 -7 -22
-20 -5 -12 17 -13 17 -13 2 0 -20 -16 -23 -22 -5 -3 8 -10 4 -17 -11 -18 -32
3 -55 25 -27 20 28 27 14 9 -20 -8 -16 -15 -35 -15 -42 0 -16 -32 -75 -43 -80
-4 -2 -5 -8 -1 -13 3 -5 1 -9 -5 -9 -5 0 -16 -3 -25 -6 -9 -3 -16 -1 -16 5 0
6 6 11 14 11 27 0 35 23 21 58 -17 40 -38 43 -29 5 7 -28 7 -28 -15 -9 -26 24
-27 33 -1 41 11 3 20 15 20 26 0 22 -18 26 -26 5 -3 -8 -16 -17 -29 -20 -16
-4 -22 -11 -19 -21 4 -8 2 -15 -2 -15 -5 0 -9 -31 -10 -69 -1 -61 1 -71 20
-80 20 -11 20 -11 -2 -7 -19 4 -23 1 -19 -14 3 -13 1 -17 -10 -13 -8 3 -13 14
-10 24 3 11 1 19 -4 19 -5 0 -9 -4 -10 -10 -4 -44 0 -62 17 -74 19 -14 18 -15
-6 -26 -21 -9 -15 -9 30 -5 39 4 51 2 40 -5 -8 -5 -27 -10 -42 -10 -15 0 -38
-4 -53 -10 -22 -9 -27 -7 -35 15 -5 14 -7 38 -4 55 4 19 1 38 -10 54 -9 14
-12 27 -7 30 5 3 9 49 10 102 1 53 6 105 12 116 6 11 6 25 1 34 -11 20 1 79
20 102 8 9 22 34 32 55 10 21 25 41 34 44 9 4 19 10 22 13 5 7 42 29 49 30 2
0 0 -7 -3 -16z m8404 -35 c0 -62 -34 -98 -95 -99 -77 -3 -180 -25 -181 -41 0
-7 -2 -30 -2 -50 -1 -20 -11 -47 -22 -60 l-20 -24 10 28 c13 36 13 74 0 82
-18 11 -51 -57 -45 -90 4 -16 8 -48 11 -70 13 -116 60 -151 185 -140 87 8 88
9 73 84 -6 29 -10 32 -38 30 -70 -4 -106 27 -106 92 0 26 4 28 48 34 26 4 70
11 97 17 28 5 63 8 80 6 33 -3 27 21 63 -226 16 -110 16 -124 2 -136 -8 -8
-56 -19 -107 -25 -107 -13 -123 -18 -123 -38 0 -8 -5 -11 -10 -8 -6 4 -8 11
-5 16 4 5 -14 9 -40 9 -56 0 -135 36 -178 81 -35 37 -66 103 -67 144 -1 17 -5
39 -10 50 -15 34 -11 -27 10 -165 21 -143 20 -146 -51 -156 l-47 -6 -68 73
c-137 146 -247 254 -240 235 3 -10 8 -46 11 -80 3 -33 10 -92 16 -131 6 -38 9
-83 7 -100 -3 -27 -7 -30 -51 -38 -32 -5 -54 -4 -62 3 -7 6 -15 38 -19 73 -4
34 -15 114 -25 177 -11 63 -25 163 -31 222 -15 129 -12 138 62 138 48 0 50 -1
125 -80 42 -44 83 -80 90 -80 7 0 13 -6 13 -14 0 -8 28 -43 61 -77 l61 -64 -7
50 c-4 28 -10 52 -13 55 -4 3 -7 28 -8 55 0 28 -2 84 -5 125 l-4 74 54 9 c46
8 57 6 66 -7 16 -22 38 -20 53 4 7 11 20 20 30 20 13 -1 11 -6 -11 -25 -15
-14 -35 -25 -44 -25 -13 0 -14 -11 -8 -82 3 -46 10 -99 15 -118 7 -33 8 -32
10 20 2 99 53 188 133 234 31 18 68 27 147 36 58 6 121 14 140 19 47 11 70 -2
70 -40z m-8313 35 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z
m2454 -25 c-5 -13 -10 -19 -10 -12 -1 15 10 45 15 40 3 -2 0 -15 -5 -28z
m3299 27 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10
-4z m-5717 -26 c-6 -22 -21 -35 -30 -26 -5 5 24 46 33 46 1 0 0 -9 -3 -20z
m7960 13 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m-8572 -49
c-5 -21 -9 -30 -10 -19 -1 24 10 67 15 62 3 -2 0 -22 -5 -43z m799 26 c0 -15
-7 -20 -26 -20 -39 0 -94 -29 -94 -50 0 -10 -7 -20 -15 -24 -30 -11 -10 55 24
81 9 7 28 13 43 13 15 0 30 5 33 10 11 18 35 11 35 -10z m5500 -20 c0 -5 -5
-10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m136 -6 c-3 -9
-6 -20 -6 -25 0 -6 -4 -7 -10 -4 -16 10 -12 45 6 45 11 0 14 -5 10 -16z m2711
10 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-8577 -34 c0 -5 -1
-13 -2 -17 -4 -10 -98 -12 -98 -2 0 11 22 17 53 13 15 -1 27 2 27 7 0 5 5 9
10 9 6 0 10 -4 10 -10z m4338 3 c-10 -2 -26 -2 -35 0 -10 3 -2 5 17 5 19 0 27
-2 18 -5z m-1901 -25 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z
m513 2 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11
-10z m1190 1 c-20 -4 -41 -11 -48 -15 -7 -4 -18 -4 -24 0 -16 10 24 22 72 22
l35 0 -35 -7z m95 2 c-11 -3 -25 -9 -31 -14 -8 -6 -14 -4 -18 6 -4 12 2 15 32
14 21 -1 29 -3 17 -6z m-4812 -33 c-7 -28 -13 -25 -13 6 0 14 4 23 9 20 5 -3
7 -15 4 -26z m4614 14 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z
m-3822 -26 c-4 -13 -18 -33 -31 -46 -26 -23 -26 -62 -1 -62 13 0 17 -74 4 -86
-3 -4 -4 -17 -1 -29 3 -13 0 -26 -6 -30 -19 -12 -23 3 -12 47 6 23 10 43 9 45
-4 3 -47 -38 -70 -66 -10 -13 -15 -31 -12 -44 6 -28 -22 -68 -34 -49 -7 11 -9
11 -14 -2 -6 -15 9 -21 36 -17 4 0 7 -8 7 -20 0 -19 -1 -19 -16 -5 -21 21 -29
20 -31 -3 -1 -14 3 -18 13 -14 9 3 14 -2 14 -16 0 -12 -4 -21 -8 -21 -14 0
-53 50 -47 60 4 6 -1 10 -9 10 -9 0 -16 5 -16 11 0 6 7 8 16 5 11 -4 22 9 40
52 56 127 75 162 89 162 8 0 15 7 15 15 0 8 -5 15 -10 15 -7 0 -7 6 -1 19 6
10 8 21 4 24 -9 10 41 66 60 66 14 1 17 -4 12 -21z m3740 12 c-3 -5 -22 -10
-41 -10 -21 0 -33 4 -29 10 3 6 22 10 41 10 21 0 33 -4 29 -10z m3135 2 c0 -5
-7 -17 -15 -28 -15 -19 -20 -8 -9 20 6 17 24 22 24 8z m-3262 -19 c-10 -2 -28
-2 -40 0 -13 2 -5 4 17 4 22 1 32 -1 23 -4z m-235 -35 c-58 -12 -110 -17 -120
-13 -21 9 104 32 177 32 34 0 19 -5 -57 -19z m-4188 -95 c-3 -29 -11 -53 -16
-53 -5 0 -7 10 -5 23 3 12 8 49 12 82 6 43 9 52 12 30 2 -16 1 -53 -3 -82z
m6268 87 c-10 -11 -21 -20 -25 -20 -5 0 -3 9 4 20 7 11 18 20 25 20 9 0 7 -6
-4 -20z m1214 -15 c12 -8 43 -56 70 -106 26 -51 56 -94 65 -97 9 -2 14 -10 12
-17 -5 -13 29 -83 106 -220 22 -38 39 -80 40 -93 0 -43 -97 -58 -137 -20 -19
18 -23 19 -23 5 0 -9 -5 -19 -11 -22 -19 -13 -21 3 -4 33 17 29 17 30 -19 93
-20 35 -41 71 -46 79 -5 8 -24 44 -41 79 -22 44 -34 60 -39 50 -7 -12 -22 -73
-36 -149 -3 -13 -12 -56 -21 -95 -8 -38 -18 -82 -20 -98 -7 -36 -33 -49 -91
-45 l-47 3 -36 70 c-20 39 -41 75 -45 80 -5 6 -26 44 -46 85 -60 122 -62 124
-78 49 -33 -165 -71 -323 -77 -330 -5 -3 -31 -9 -59 -13 -61 -8 -81 7 -69 51
5 16 9 33 10 38 4 28 21 105 55 250 21 88 44 187 50 220 6 33 19 66 28 73 22
16 73 15 99 -3 21 -15 85 -121 155 -257 22 -43 43 -78 48 -78 4 0 10 19 14 43
9 53 9 57 1 85 -6 17 -4 21 7 17 10 -4 18 15 31 79 20 97 22 129 7 106 -5 -8
-10 -10 -10 -4 0 17 40 65 64 74 25 11 62 4 93 -15z m-6774 0 c-3 -9 -8 -14
-10 -11 -3 3 -2 9 2 15 9 16 15 13 8 -4z m3327 5 c-20 -13 -43 -13 -35 0 3 6
16 10 28 10 18 0 19 -2 7 -10z m5151 -7 c13 -16 12 -17 -3 -4 -10 7 -18 15
-18 17 0 8 8 3 21 -13z m-5206 -3 c-3 -5 -12 -7 -20 -3 -21 7 -19 13 6 13 11
0 18 -4 14 -10z m1055 -5 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8 5 15 10
15 6 0 10 -7 10 -15z m4276 0 c-25 -19 -37 -19 -30 0 4 8 16 15 28 15 21 -1
21 -1 2 -15z m-5410 -5 c-16 -5 -33 -7 -37 -4 -5 3 -31 -2 -59 -10 -28 -8 -86
-18 -128 -21 -77 -5 -77 -5 -34 9 23 8 75 17 114 20 40 4 74 9 77 11 2 3 25 5
50 4 42 -1 44 -1 17 -9z m2614 -10 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6
-1 10 4 10 6 0 11 -4 11 -10z m1110 -5 c0 -8 -4 -15 -9 -15 -13 0 -22 16 -14
24 11 11 23 6 23 -9z m1440 -2 c0 -10 -4 -25 -10 -33 -6 -10 -9 -5 -8 18 1 33
18 47 18 15z m-8370 -3 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10
10 6 0 10 -4 10 -10z m3815 -10 c3 -5 -1 -10 -9 -10 -8 0 -18 5 -21 10 -3 6 1
10 9 10 8 0 18 -4 21 -10z m-925 -10 c0 -10 -17 -15 -87 -24 -23 -4 -44 -2
-48 3 -5 9 18 16 90 24 17 2 33 5 38 6 4 0 7 -3 7 -9z m3097 -35 c-16 -12 -16
-14 -2 -29 9 -9 21 -16 27 -16 19 0 95 -90 106 -127 7 -21 17 -79 23 -129 13
-105 5 -145 -40 -216 -41 -64 -98 -99 -186 -111 -67 -10 -73 -9 -125 17 -30
15 -66 37 -79 49 -21 19 -23 19 -18 4 10 -28 -17 -70 -61 -94 -21 -13 -41 -20
-44 -17 -3 2 -47 0 -97 -6 -151 -19 -231 12 -299 115 -24 37 -32 63 -42 146
-18 150 -4 206 73 279 46 45 98 65 211 80 44 6 87 17 97 26 11 10 19 12 24 5
3 -6 19 -11 35 -11 44 0 56 -58 20 -103 -25 -31 -64 -44 -153 -51 -82 -7 -126
-25 -156 -62 -21 -26 -23 -36 -18 -110 3 -45 14 -97 23 -117 20 -42 69 -70
123 -69 41 1 223 31 231 38 2 3 -2 14 -9 25 -7 12 -18 66 -25 121 -16 125 -6
194 39 248 7 8 15 27 19 43 3 15 20 42 37 60 l31 32 -20 -37 -21 -37 27 10
c14 6 33 16 41 22 8 7 48 12 98 12 45 0 83 3 83 8 0 4 7 7 15 7 8 0 15 5 15
12 0 6 3 8 7 4 5 -4 0 -14 -10 -21z m-3272 5 c-7 -12 -52 -14 -58 -2 -3 4 11
8 30 10 21 1 32 -2 28 -8z m-140 -9 c-16 -14 -118 -29 -155 -23 -29 5 -21 8
50 18 115 15 116 15 105 5z m5767 -13 c-7 -7 -12 -8 -12 -2 0 14 12 26 19 19
2 -3 -1 -11 -7 -17z m284 -13 c-15 -14 -31 -25 -37 -25 -5 0 -9 -5 -9 -11 0
-6 -8 -9 -19 -6 -26 6 -50 -38 -46 -85 2 -27 -1 -38 -10 -38 -8 0 -11 7 -8 20
3 11 0 27 -7 35 -7 8 -8 15 -3 15 6 0 8 11 6 24 -4 18 0 26 15 30 11 4 37 20
57 36 21 17 49 30 63 30 l25 0 -27 -25z m163 -9 c0 -6 -9 -24 -20 -41 -18 -29
-24 -42 -36 -75 -4 -11 -8 -8 -13 10 -3 14 -2 28 3 31 5 3 8 10 8 15 -3 31 22
71 44 71 8 0 15 -5 14 -11z m-6485 -6 c-5 -5 -37 -11 -71 -14 -34 -3 -91 -13
-128 -22 -46 -12 -69 -14 -77 -6 -12 12 14 18 142 37 110 16 147 18 134 5z
m5988 -12 c-7 -7 -12 -8 -12 -2 0 14 12 26 19 19 2 -3 -1 -11 -7 -17z m-8356
-35 c-8 -15 -15 -22 -16 -15 0 14 22 53 28 47 2 -2 -3 -16 -12 -32z m-146 17
c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z
m4980 -7 c-1 -10 -36 -53 -44 -53 -7 0 4 31 17 48 10 13 27 16 27 5z m-2810
-12 c-23 -14 -137 -25 -125 -13 13 14 41 20 95 21 30 0 40 -3 30 -8z m6034
-35 c-3 -9 -10 -16 -15 -16 -6 0 -5 9 1 21 13 24 24 20 14 -5z m603 18 c-3 -3
-12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-8497 -8 c0 -2 -7 -19 -15
-39 -8 -19 -11 -38 -7 -42 4 -3 -1 -13 -12 -20 -13 -10 -17 -21 -12 -38 5 -22
2 -26 -30 -32 -20 -3 -38 -4 -40 -2 -2 2 3 26 11 53 8 27 15 63 15 81 0 22 5
33 18 36 22 6 72 8 72 3z m1625 -6 c-28 -12 -45 -12 -45 0 0 6 15 10 33 9 27
0 29 -2 12 -9z m2245 6 c0 -11 -19 -15 -25 -6 -3 5 1 10 9 10 9 0 16 -2 16 -4z
m45 -6 c-3 -5 -10 -10 -16 -10 -5 0 -9 5 -9 10 0 6 7 10 16 10 8 0 12 -4 9
-10z m-2400 -10 c3 -5 0 -10 -7 -11 -7 0 -32 -2 -55 -5 -24 -2 -43 0 -43 5 0
6 19 12 43 14 23 2 45 4 49 5 4 1 10 -2 13 -8z m50 0 c-3 -5 -13 -10 -21 -10
-8 0 -14 5 -14 10 0 6 9 10 21 10 11 0 17 -4 14 -10z m-2388 -47 c-3 -10 -5
-2 -5 17 0 19 2 27 5 18 2 -10 2 -26 0 -35z m638 37 c-3 -5 -12 -10 -18 -10
-7 0 -6 4 3 10 19 12 23 12 15 0z m4445 0 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4
10 3 6 8 10 11 10 2 0 4 -4 4 -10z m580 -5 c10 -12 -1 -41 -59 -158 -39 -79
-71 -146 -71 -149 0 -3 10 -16 23 -30 78 -86 116 -129 119 -135 2 -5 18 -21
37 -38 19 -16 31 -24 27 -17 -4 7 -1 12 8 12 9 0 16 8 16 19 0 13 10 20 31 25
67 13 74 10 86 -41 6 -27 8 -51 5 -55 -4 -4 -30 -10 -58 -13 -46 -7 -52 -5
-57 14 -7 26 -27 28 -27 1 0 -14 -11 -22 -42 -31 -61 -16 -108 1 -149 54 -50
64 -80 92 -98 92 -9 0 -16 7 -17 15 -1 8 -2 21 -2 28 -1 7 -18 12 -43 12 -38
0 -41 -2 -36 -22 4 -12 12 -63 18 -114 15 -110 11 -118 -60 -122 -40 -2 -52 1
-59 15 -9 18 -15 64 -53 378 -11 94 -21 175 -23 181 -13 38 57 51 101 18 31
-24 39 -46 48 -136 6 -67 7 -68 35 -68 34 0 28 -7 106 139 52 100 65 116 95
127 47 17 84 17 99 -1z m-1030 1 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6
5 11 10 11 6 0 10 -2 10 -4z m-4080 -16 c0 -5 -11 -10 -25 -10 -14 0 -25 5
-25 10 0 6 11 10 25 10 14 0 25 -4 25 -10z m1600 0 c-14 -4 -79 -16 -145 -25
-66 -9 -165 -22 -220 -30 -55 -8 -136 -19 -181 -25 -45 -6 -96 -15 -115 -20
-19 -6 -56 -10 -83 -10 -57 0 -173 -18 -198 -32 -13 -6 -18 -5 -18 6 0 16 25
23 120 31 36 3 81 10 100 15 19 5 96 16 170 24 74 9 164 20 200 26 182 27 332
47 370 48 l25 0 -25 -8z m-1660 -4 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0
6 5 11 10 11 6 0 10 -2 10 -4z m8673 -16 c-3 -13 -14 -20 -29 -20 -27 0 -29 5
-11 25 19 22 46 19 40 -5z m39 -10 c3 -21 0 -30 -10 -30 -8 0 -12 6 -8 15 3 8
0 15 -6 15 -7 0 -6 5 2 15 17 20 17 20 22 -15z m-8639 -16 c-23 -23 -43 -25
-43 -4 0 17 30 38 49 33 17 -4 17 -6 -6 -29z m8887 26 c0 -5 10 -10 22 -10 12
0 19 -3 16 -6 -9 -9 -78 7 -72 17 7 12 34 11 34 -1z m-8933 -6 c-3 -3 -12 -4
-19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m8668 -60 c0 -10 8 -27 18 -36 9 -10
17 -24 17 -30 0 -7 -6 -4 -12 6 -10 17 -12 16 -25 -15 l-15 -34 -29 35 c-30
35 -41 34 -54 -7 -4 -14 -2 -23 4 -23 6 0 11 7 11 15 0 20 27 8 33 -16 4 -15
14 -19 51 -19 42 0 46 3 62 35 21 45 35 54 24 15 -5 -16 -6 -30 -3 -30 3 0 0
-6 -6 -14 -8 -9 -8 -16 -2 -20 5 -4 12 3 16 14 3 11 10 20 15 20 5 0 2 -12 -6
-27 -7 -16 -14 -34 -14 -42 0 -11 -3 -11 -15 -1 -8 6 -19 9 -25 5 -18 -11 -11
-24 18 -28 26 -4 25 -4 -5 -6 -27 -1 -32 3 -35 26 -3 24 -8 28 -45 31 -26 2
-43 -1 -43 -7 0 -6 6 -11 13 -11 6 -1 -4 -8 -23 -17 -19 -9 -41 -15 -47 -14
-7 1 -13 -6 -13 -15 0 -13 6 -15 28 -10 74 19 87 20 76 5 -6 -8 -14 -27 -18
-43 -6 -23 -4 -27 8 -23 10 4 16 1 16 -7 0 -19 -35 -26 -76 -14 -30 8 -34 12
-23 25 11 13 9 14 -17 10 -38 -6 -74 -28 -74 -44 0 -7 -4 -13 -10 -13 -5 0
-10 -7 -10 -15 0 -8 5 -15 11 -15 7 0 9 -9 6 -22 -6 -22 -6 -22 13 2 l19 25
-6 -37 c-4 -28 -2 -38 7 -38 7 0 11 3 7 6 -3 4 1 20 9 36 8 16 13 42 11 56 -2
15 -2 21 0 14 4 -10 16 -12 45 -7 36 6 40 5 35 -9 -4 -10 -3 -15 3 -11 5 3 18
1 27 -4 17 -10 17 -10 1 -11 -10 0 -20 1 -22 3 -2 1 -14 -11 -26 -26 -15 -18
-28 -26 -40 -22 -12 4 -19 -1 -22 -13 -5 -17 -14 -19 -72 -17 -58 2 -66 0 -61
-15 6 -17 2 -18 -120 -23 -34 -2 -40 1 -43 20 -3 18 5 26 37 39 25 10 48 28
59 49 11 18 37 42 59 54 25 15 45 36 55 60 9 21 23 45 32 53 8 8 26 43 41 78
15 35 33 74 41 87 47 78 39 72 86 58 31 -9 43 -17 43 -31z m65 36 c0 -5 -4
-10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-4189 -32
c54 -30 89 -91 89 -154 0 -68 -13 -100 -57 -146 l-36 -37 29 -35 c16 -20 41
-38 57 -42 17 -4 27 -13 27 -24 0 -10 11 -31 24 -47 29 -34 20 -68 -21 -84
-56 -21 -86 -5 -185 101 -95 102 -107 109 -166 94 -24 -7 -24 -9 -3 -166 4
-32 4 -62 1 -69 -12 -18 -102 -32 -116 -17 -12 14 -27 97 -44 243 -6 49 -13
91 -16 94 -6 6 -4 -33 5 -104 11 -84 -22 -168 -87 -225 -52 -44 -86 -55 -182
-55 -77 0 -140 31 -190 94 l-31 38 -36 -33 c-49 -45 -55 -34 -9 13 29 29 36
44 32 63 -17 79 -27 181 -23 222 9 70 73 160 140 194 43 23 157 30 199 14 80
-32 146 -100 166 -171 15 -49 15 -9 0 108 -10 85 -10 97 4 107 9 7 65 18 125
25 59 7 122 17 138 21 44 11 125 0 166 -22z m4403 24 c7 -13 -14 -80 -42 -131
-26 -47 -89 -111 -109 -111 -7 0 -13 -4 -13 -10 0 -5 5 -10 10 -10 6 0 10 -4
10 -10 0 -14 -27 -12 -36 2 -14 21 -4 82 16 104 11 11 20 28 20 37 0 9 17 29
38 45 33 23 38 32 35 60 -4 31 -3 32 31 32 19 0 37 -4 40 -8z m-1934 -29 c-1
-10 -7 -29 -15 -43 l-14 -25 -1 36 c0 20 3 39 7 42 12 13 23 7 23 -10z m-6990
-18 c0 -8 -7 -15 -16 -15 -14 0 -14 3 -4 15 7 8 14 15 16 15 2 0 4 -7 4 -15z
m2725 5 c-3 -5 -12 -10 -18 -10 -7 0 -6 4 3 10 19 12 23 12 15 0z m5965 0 c0
-5 -4 -10 -9 -10 -6 0 -13 5 -16 10 -3 6 1 10 9 10 9 0 16 -4 16 -10z m-9233
-32 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m573 -12 c-14 -14
-22 -2 -14 18 5 14 9 15 15 4 4 -7 4 -17 -1 -22z m108 15 c3 -8 -1 -12 -9 -9
-7 2 -15 10 -17 17 -3 8 1 12 9 9 7 -2 15 -10 17 -17z m5459 -18 c-3 -10 -5
-4 -5 12 0 17 2 24 5 18 2 -7 2 -21 0 -30z m-4957 18 c0 -10 -71 -21 -85 -12
-5 3 4 9 20 12 45 10 65 10 65 0z m-160 -20 c0 -13 -26 -21 -63 -21 l-32 1 25
14 c28 16 70 20 70 6z m7117 -13 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4
-12 1 -19z m-8142 -25 c-10 -57 -26 -93 -46 -104 -31 -16 -203 -48 -213 -39
-10 10 12 17 89 25 93 10 143 50 159 128 9 41 18 33 11 -10z m9307 15 c-6 -6
-15 -8 -19 -4 -4 4 -1 11 7 16 19 12 27 3 12 -12z m-5692 2 c0 -5 -10 -10 -22
-10 -19 0 -20 2 -8 10 19 13 30 13 30 0z m4332 -76 c3 -38 3 -38 -40 -47 -24
-5 -49 -6 -56 -4 -7 3 -13 26 -15 54 l-2 49 48 10 c54 10 59 6 65 -62z m995
70 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-4797 -47 c0 -13
-9 -50 -19 -83 -28 -86 -55 -191 -92 -349 -18 -77 -37 -145 -41 -151 -5 -6
-27 -14 -51 -17 -23 -2 -48 -6 -56 -8 -9 -3 -17 7 -21 28 -4 18 -30 72 -57
120 -28 49 -66 116 -84 150 -35 64 -49 77 -49 45 0 -15 -28 -133 -77 -319 -12
-46 -21 -63 -37 -67 -37 -10 -83 -6 -95 8 -11 13 -16 22 -116 211 -32 61 -66
121 -75 135 -9 14 -34 60 -55 104 -46 93 -42 109 27 114 51 3 78 -17 113 -85
27 -52 127 -237 131 -242 2 -2 7 -2 10 1 6 6 83 291 91 339 8 44 77 64 119 34
18 -12 104 -144 104 -158 0 -2 22 -44 49 -92 l48 -87 48 191 c26 105 52 195
59 200 15 13 87 18 109 8 9 -4 17 -18 17 -30z m1637 11 c-3 -8 -6 -5 -6 6 -1
11 2 17 5 13 3 -3 4 -12 1 -19z m-57 -13 c-7 -8 -17 -15 -22 -15 -6 0 -5 7 2
15 7 8 17 15 22 15 6 0 5 -7 -2 -15z m-47 -43 c-4 -18 -82 -66 -92 -57 -8 8
22 35 39 35 9 0 25 9 35 20 22 25 24 25 18 2z m2520 1 c-7 -3 -13 -11 -13 -19
0 -8 -9 -23 -20 -34 l-20 -20 0 23 c0 12 4 27 8 32 10 15 46 33 52 27 3 -2 -1
-7 -7 -9z m517 3 c0 -2 -7 -7 -16 -10 -8 -3 -12 -2 -9 4 6 10 25 14 25 6z
m-5560 -45 c8 -6 14 -25 14 -47 0 -52 -46 -89 -120 -99 -53 -6 -55 -7 -49 -33
3 -15 14 -99 25 -187 10 -88 22 -180 25 -205 3 -25 2 -51 -3 -58 -5 -7 -31
-15 -59 -19 -43 -5 -52 -3 -61 13 -6 11 -22 112 -36 224 -15 113 -29 213 -32
222 -4 15 -14 16 -69 11 -49 -4 -70 -2 -88 10 -32 21 -52 70 -39 94 11 18 33
23 267 53 91 12 198 27 205 29 3 0 12 -3 20 -8z m767 -26 c-7 -13 -14 -20 -15
-14 -2 5 2 16 8 24 18 21 20 18 7 -10z m3533 20 c0 -8 -24 -48 -37 -63 -17
-18 -16 -9 4 33 14 30 33 47 33 30z m-7730 -13 c0 -13 -12 -22 -22 -16 -10 6
-1 24 13 24 5 0 9 -4 9 -8z m-110 -22 c-68 -15 -140 -21 -140 -11 0 10 52 17
135 18 l40 1 -35 -8z m6120 -10 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5
10 10 10 6 0 10 -4 10 -10z m2551 -13 c-10 -9 -11 -8 -5 6 3 10 9 15 12 12 3
-3 0 -11 -7 -18z m266 -9 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z
m-5906 -23 c7 -40 -13 -80 -48 -93 -15 -6 -62 -16 -103 -21 -187 -26 -213 -30
-215 -33 -4 -3 13 -100 18 -105 2 -1 40 3 84 9 66 10 84 9 97 -1 22 -18 20
-45 -6 -86 -21 -30 -30 -35 -90 -46 l-68 -12 0 -39 c0 -21 4 -38 10 -38 5 0 7
-7 4 -15 -5 -12 0 -14 22 -10 16 3 99 14 184 25 148 19 156 19 168 3 19 -25 8
-80 -20 -103 -25 -21 -31 -23 -198 -45 -63 -8 -145 -20 -182 -27 -78 -13 -97
-4 -100 46 -1 24 -2 21 -7 -14 l-6 -45 -54 -9 -54 -9 -60 64 c-33 35 -103 108
-155 162 l-94 97 6 -37 c3 -21 13 -98 22 -171 19 -159 17 -164 -60 -160 -49 3
-51 4 -59 38 -5 28 -77 531 -77 545 0 15 38 35 66 35 60 0 67 -6 269 -223 76
-82 100 -103 90 -74 -3 6 -14 79 -24 162 -22 165 -18 185 29 185 15 0 34 2 42
5 39 14 47 -17 83 -330 9 -77 20 -151 26 -165 12 -33 7 18 -26 278 -15 111
-25 209 -22 216 5 12 66 24 212 41 28 3 88 12 135 19 47 8 101 13 120 13 30
-2 36 -6 41 -32z m6599 19 c0 -8 -4 -12 -10 -9 -5 3 -10 10 -10 16 0 5 5 9 10
9 6 0 10 -7 10 -16z m-9925 -28 c-33 -19 -80 -44 -104 -56 -84 -44 -220 -198
-291 -329 -17 -32 -40 -127 -46 -189 -3 -29 -10 -65 -14 -80 -7 -22 -8 -17 -9
28 -1 270 181 529 453 642 69 28 75 19 11 -16z m9881 -3 c-10 -10 -19 5 -10
18 6 11 8 11 12 0 2 -7 1 -15 -2 -18z m-9399 -35 c-3 -7 -5 -2 -5 12 0 14 2
19 5 13 2 -7 2 -19 0 -25z m7729 -20 c-3 -13 -6 -26 -6 -30 0 -5 -6 -8 -14 -8
-7 0 -17 -10 -21 -22 -4 -15 -8 -19 -12 -10 -3 8 0 19 6 25 6 6 11 21 11 32 0
25 8 35 28 35 10 0 13 -7 8 -22z m104 14 c0 -5 -7 -17 -15 -28 -14 -19 -14
-19 -15 2 0 11 3 24 7 27 9 10 23 9 23 -1z m1038 -28 c-13 -27 -63 -60 -72
-46 -8 14 18 56 44 69 32 16 42 8 28 -23z m-2718 5 c0 -5 -4 -9 -10 -9 -5 0
-10 7 -10 16 0 8 5 12 10 9 6 -3 10 -10 10 -16z m1637 -1 c-3 -8 -6 -5 -6 6
-1 11 2 17 5 13 3 -3 4 -12 1 -19z m-2150 -10 c-3 -8 -6 -5 -6 6 -1 11 2 17 5
13 3 -3 4 -12 1 -19z m2853 15 c-1 -20 -48 -62 -75 -69 l-30 -7 40 41 c37 37
65 52 65 35z m-1816 -28 c-4 -8 -8 -15 -10 -15 -2 0 -4 7 -4 15 0 8 4 15 10
15 5 0 7 -7 4 -15z m96 11 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11
10 11 6 0 10 -2 10 -4z m-630 -10 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6
5 11 10 11 6 0 10 -2 10 -4z m422 -18 c-7 -7 -12 -8 -12 -2 0 14 12 26 19 19
2 -3 -1 -11 -7 -17z m1598 6 c0 -2 -12 -4 -27 -4 -21 0 -24 3 -14 9 13 8 41 5
41 -5z m-6372 -40 c3 -35 -1 -45 -27 -66 -20 -17 -48 -28 -85 -32 -55 -7 -56
-8 -56 -39 0 -18 5 -61 10 -97 30 -196 40 -278 40 -315 l0 -42 -56 -7 c-30 -3
-59 -4 -63 -1 -9 5 -25 82 -22 102 1 7 -3 47 -9 90 -5 43 -14 112 -20 153 -17
129 -10 120 -92 118 -68 -2 -72 0 -99 30 -29 32 -38 75 -19 87 6 4 79 16 163
27 84 10 179 23 212 28 33 4 74 7 90 6 28 -1 30 -4 33 -42z m3857 26 c3 -5 1
-10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m3535 -4 c0 -2
-9 -6 -20 -9 -11 -3 -18 -1 -14 4 5 9 34 13 34 5z m-8980 -25 c0 -12 -20 -25
-27 -18 -7 7 6 27 18 27 5 0 9 -4 9 -9z m7824 -19 c-6 -4 -13 -15 -17 -24 -7
-20 -37 -24 -37 -5 0 12 51 47 60 40 2 -2 0 -7 -6 -11z m-6770 -18 c18 -18 20
-33 6 -68 -17 -45 -54 -59 -203 -78 -77 -10 -144 -21 -148 -25 -4 -5 -5 -29
-2 -55 l6 -48 56 7 c31 4 74 8 96 8 37 0 40 -2 43 -29 5 -42 -16 -83 -49 -96
-16 -7 -29 -16 -29 -21 0 -5 -20 -6 -45 -2 -52 7 -50 17 -34 -128 7 -62 7
-105 2 -110 -5 -5 -32 -12 -60 -16 -65 -7 -67 -4 -87 163 -9 71 -20 155 -25
187 -6 32 -13 103 -17 157 -6 81 -4 100 7 104 8 3 70 13 139 22 69 9 163 22
210 29 118 17 117 17 134 -1z m4971 4 c12 11 -6 -20 -23 -41 -24 -28 -5 -33
25 -6 21 18 24 19 13 4 -8 -11 -25 -31 -37 -44 l-23 -24 0 55 c0 59 6 74 25
58 7 -5 16 -6 20 -2z m254 3 c12 -8 9 -11 -14 -17 -16 -4 -40 -20 -53 -36 -13
-15 -30 -28 -37 -28 -19 0 -71 -51 -80 -77 -3 -13 -11 -23 -16 -23 -5 0 -9 -7
-9 -15 0 -9 -9 -15 -21 -15 -15 0 -19 -5 -15 -16 3 -8 2 -12 -4 -9 -6 4 -17
-1 -26 -9 -17 -18 -44 -21 -44 -6 0 6 7 10 16 10 8 0 12 5 8 11 -7 12 43 69
60 69 6 0 28 18 49 40 20 22 39 40 42 40 8 0 55 48 55 57 0 28 57 44 89 24z
m1203 -31 c-18 -15 -32 -30 -32 -34 0 -11 -30 -39 -36 -33 -10 10 35 83 58 94
38 17 44 2 10 -27z m103 30 c3 -5 -1 -10 -9 -10 -9 0 -16 5 -16 10 0 6 4 10 9
10 6 0 13 -4 16 -10z m-2155 -20 c0 -5 -8 -10 -17 -10 -15 0 -16 2 -3 10 19
12 20 12 20 0z m942 -12 c-7 -7 -12 -8 -12 -2 0 14 12 26 19 19 2 -3 -1 -11
-7 -17z m1378 -13 c0 -8 -3 -14 -7 -13 -5 1 -26 -6 -48 -15 -21 -10 -46 -16
-55 -13 -11 3 -9 6 8 11 12 4 22 11 22 16 0 5 10 9 23 9 12 0 29 5 37 10 20
13 20 13 20 -5z m-8155 -15 c-3 -5 -16 -10 -28 -9 -21 0 -21 1 -2 9 28 12 37
12 30 0z m8499 1 c7 -10 -52 -37 -61 -27 -3 3 1 6 11 6 9 0 19 7 22 15 7 17
20 20 28 6z m-8847 -17 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z
m1243 -9 c17 -9 31 -25 34 -42 6 -27 62 -415 73 -500 6 -47 6 -47 -64 -57 -54
-8 -69 7 -78 76 -3 29 -15 116 -25 193 -36 276 -42 324 -35 335 9 15 62 12 95
-5z m-140 -25 c12 -7 3 -54 -60 -307 -40 -164 -77 -303 -82 -309 -11 -15 -85
-27 -103 -17 -8 5 -26 31 -41 58 -148 280 -145 275 -159 275 -5 0 -29 -73 -52
-163 -58 -224 -52 -213 -109 -221 -33 -4 -52 -2 -60 6 -7 7 -35 56 -62 108
-27 52 -73 138 -102 190 -117 212 -125 230 -114 248 7 11 27 18 57 20 57 4 70
-9 139 -138 98 -184 112 -206 116 -185 1 11 11 49 21 85 11 36 29 103 41 150
34 131 38 139 76 146 42 8 111 -22 116 -51 5 -25 148 -288 154 -283 4 5 53
186 66 248 22 105 38 139 66 143 50 7 77 7 92 -3z m6994 -2 c-15 -26 -38 -48
-50 -48 -20 0 -7 24 24 43 33 20 35 20 26 5z m286 -19 c14 -25 3 -40 -13 -19
-7 9 -23 21 -37 27 l-25 12 32 0 c23 1 36 -5 43 -20z m40 -39 c0 -5 -2 -10 -4
-10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m-2723 -42 c-3 -8 -6
-5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m1913 -8 c0 -5 -4 -10 -10 -10 -5
0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-2687 -17 c-7 -3 -13 -12
-14 -21 0 -13 -2 -13 -8 3 -6 14 -4 22 8 26 9 4 18 4 21 1 3 -2 -1 -7 -7 -9z
m2315 4 c13 4 11 0 -5 -16 -29 -27 -31 -27 -25 7 3 15 7 22 9 15 2 -7 12 -9
21 -6z m1147 -4 c-5 -10 -12 -40 -16 -68 -4 -27 -13 -72 -19 -100 -6 -27 -13
-78 -15 -113 -3 -34 -9 -71 -15 -82 -6 -11 -9 -23 -8 -27 4 -18 -15 -109 -27
-128 -6 -11 -25 -56 -40 -100 -15 -44 -31 -84 -35 -90 -12 -15 -15 -58 -7 -72
5 -8 3 -13 -3 -13 -6 0 -8 -4 -5 -10 3 -5 2 -10 -3 -10 -9 0 -42 -64 -42 -83
0 -7 -13 -38 -29 -69 -19 -38 -25 -58 -17 -58 32 0 116 32 116 44 0 13 15 23
35 24 6 0 9 -4 8 -9 -5 -15 48 -10 104 10 51 17 63 17 63 0 0 -7 -81 -54 -87
-51 -10 5 -33 -13 -33 -27 -1 -14 -2 -14 -11 1 -8 15 -14 16 -37 5 -15 -7 -37
-23 -49 -37 -22 -24 -22 -24 -23 -4 0 17 -5 20 -25 17 -30 -6 -61 -49 -47 -66
5 -7 11 -31 14 -54 3 -36 0 -43 -17 -46 -19 -4 -19 -5 3 -12 12 -4 22 -11 22
-16 0 -15 -38 -10 -44 6 -9 24 -24 17 -28 -12 -4 -33 -23 -41 -37 -14 -13 23
7 80 26 74 16 -6 28 18 14 27 -7 4 -13 17 -14 29 -1 21 -2 21 -15 4 -8 -10
-21 -34 -29 -53 -8 -19 -28 -56 -45 -82 -19 -30 -31 -63 -31 -88 -2 -29 -6
-40 -18 -40 -13 0 -69 -65 -69 -81 0 -7 78 -4 86 4 5 4 2 13 -6 18 -13 9 -12
10 2 6 10 -4 18 1 21 15 4 13 11 19 21 15 22 -9 33 12 42 78 7 50 9 55 14 30
4 -26 9 -30 38 -30 18 1 32 -4 32 -11 0 -7 -13 -10 -34 -9 l-34 3 13 -31 c17
-42 49 -51 39 -11 -6 23 -4 26 9 21 9 -3 19 -2 22 3 4 6 13 10 22 10 13 0 13
-2 2 -16 -12 -15 -11 -16 11 -9 14 4 27 12 28 16 2 5 8 6 13 3 26 -16 81 71
57 89 -9 6 -8 7 4 3 12 -3 24 5 38 24 17 24 19 33 10 50 l-11 21 -22 -24 c-12
-13 -31 -27 -42 -31 -16 -6 -17 -9 -5 -16 12 -7 12 -10 -3 -16 -10 -3 -24 -18
-32 -33 -30 -55 -30 -55 -63 -36 -21 13 -29 25 -28 41 1 26 16 34 16 10 0 -8
5 -18 11 -21 12 -8 49 23 46 39 -1 6 3 10 10 9 7 -2 10 3 7 11 -3 8 4 19 16
26 20 11 21 13 5 30 -15 17 -14 18 17 24 65 13 72 12 86 -16 7 -16 17 -28 23
-28 11 0 12 13 1 25 -5 6 -6 21 -1 35 4 14 7 20 8 13 1 -13 41 -5 41 8 0 5 -4
9 -9 9 -5 0 -8 11 -8 25 0 14 3 25 8 25 5 0 9 -8 9 -18 0 -10 5 -23 11 -29 18
-18 17 -76 -2 -90 -9 -7 -20 -25 -24 -40 -3 -16 -17 -42 -31 -60 -13 -17 -24
-34 -24 -38 0 -3 -10 -17 -23 -30 -25 -28 -97 -67 -97 -54 0 14 -33 10 -57 -6
-13 -9 -20 -22 -17 -30 4 -8 1 -15 -5 -15 -6 0 -11 9 -11 20 0 15 -7 20 -25
20 -14 0 -25 -4 -25 -9 0 -5 -9 -11 -20 -14 -17 -4 -20 -11 -16 -34 2 -15 5
-32 5 -36 1 -5 6 -5 11 -2 6 3 10 -1 10 -10 0 -10 -5 -13 -11 -9 -9 5 -10 -1
-5 -20 4 -18 3 -25 -3 -21 -6 3 -6 -7 1 -27 13 -40 23 -52 15 -18 -8 30 2 72
15 64 5 -3 7 -12 4 -19 -2 -7 0 -32 6 -56 14 -64 12 -108 -6 -128 -19 -21 -21
-38 -3 -44 14 -4 -33 -78 -70 -107 -15 -12 -20 -21 -13 -25 15 -9 -49 -75 -73
-75 -13 0 -17 -7 -15 -27 2 -20 -3 -30 -19 -38 -13 -7 -20 -15 -17 -18 9 -9
-52 -70 -63 -64 -5 3 -10 -4 -10 -15 -2 -26 -31 -58 -63 -70 -20 -7 -27 -5
-40 13 -12 15 -23 19 -37 15 -15 -5 -22 -1 -26 13 -7 27 3 34 19 13 12 -15 13
-11 8 25 -5 35 -3 44 10 49 10 4 15 12 13 19 -3 6 4 17 14 23 10 7 19 8 19 3
0 -5 3 -18 7 -28 6 -16 5 -16 -10 -4 -15 12 -17 11 -17 -8 0 -15 6 -21 23 -21
18 0 23 5 22 27 0 24 -28 68 -38 61 -2 -2 -12 4 -22 12 -15 13 -19 12 -32 -5
-15 -19 -15 -19 -9 -1 6 17 5 18 -16 4 -28 -19 -74 -98 -56 -98 9 0 9 -3 1
-11 -21 -21 -44 -3 -37 28 22 97 46 143 56 112 5 -14 39 2 72 32 24 23 46 25
46 4 0 -8 6 -15 13 -16 6 0 1 -5 -12 -10 -19 -7 -21 -12 -11 -24 6 -7 18 -11
26 -8 8 3 14 -1 14 -11 0 -29 17 -17 18 13 1 28 26 78 46 93 5 3 9 24 8 45 -1
31 3 40 20 48 12 6 23 17 26 25 2 10 1 11 -5 4 -12 -16 -111 -13 -117 3 -3 7
-13 13 -23 13 -17 -1 -17 -1 -1 -14 22 -16 39 -84 18 -71 -10 6 -11 1 -7 -21
6 -27 6 -27 -5 -6 -11 23 -28 30 -28 12 0 -5 -3 -10 -8 -10 -8 0 -32 71 -32
95 0 23 -14 18 -32 -13 -16 -27 -9 -66 9 -49 4 5 2 11 -4 15 -8 5 -9 13 -3 22
8 13 10 13 10 -2 0 -9 6 -19 13 -22 19 -8 -21 -36 -41 -28 -14 5 -15 3 -5 -9
9 -11 9 -23 0 -52 -14 -42 -27 -49 -27 -13 0 14 -4 28 -10 31 -5 3 -10 -1 -10
-10 0 -9 -4 -14 -9 -11 -5 3 -16 -9 -24 -27 -9 -17 -25 -38 -36 -45 -12 -7
-21 -17 -21 -23 0 -8 5 -7 15 1 12 10 15 10 15 -4 0 -9 -6 -16 -12 -16 -10 0
-9 -3 1 -9 11 -7 9 -10 -8 -16 -11 -3 -21 -11 -21 -16 0 -12 27 -12 51 1 13 8
19 7 19 0 0 -6 -14 -13 -31 -17 -17 -3 -33 -12 -36 -19 -8 -22 -43 -17 -43 6
0 11 5 20 10 20 14 0 12 13 -5 36 -11 14 -14 15 -9 3 3 -10 1 -20 -5 -24 -6
-3 -11 -1 -11 4 0 7 -7 7 -20 -1 -23 -15 -25 -25 -7 -31 6 -2 2 -2 -10 0 -17
2 -23 -1 -23 -16 0 -21 -22 -43 -38 -39 -6 2 -31 -13 -56 -32 -44 -34 -59 -60
-34 -60 9 0 9 -3 0 -12 -7 -7 -12 -20 -12 -29 0 -11 -11 -20 -30 -24 -35 -8
-40 -25 -7 -26 12 0 17 -3 10 -5 -7 -3 -9 -12 -6 -20 3 -7 10 -11 15 -8 6 4 9
1 6 -7 -2 -7 -20 -17 -39 -23 l-34 -9 40 -13 40 -12 -55 -3 c-53 -2 -54 -2
-15 -8 31 -5 40 -10 40 -26 0 -15 -9 -21 -35 -25 -44 -8 -60 -17 -60 -36 0 -8
-10 -18 -22 -23 -19 -7 -25 -4 -34 18 -7 15 -8 33 -3 43 13 25 10 36 -3 13
-15 -26 -11 -94 7 -112 31 -30 12 -48 -33 -31 -32 12 -57 3 -33 -11 7 -5 12
-14 9 -21 -6 -18 -48 -23 -48 -6 0 7 -8 20 -18 29 -16 15 -19 15 -31 -4 -7
-12 -8 -18 -2 -14 6 3 15 -5 21 -19 7 -20 17 -26 39 -26 16 0 33 5 36 10 3 6
14 10 24 10 10 0 24 7 31 15 11 14 15 14 32 -2 18 -16 19 -16 25 5 3 12 12 22
19 22 8 0 14 7 14 15 0 21 44 29 72 14 l23 -14 -22 -3 c-25 -4 -30 -21 -11
-40 9 -9 7 -12 -10 -12 -12 0 -22 -6 -22 -13 0 -7 -8 -22 -18 -33 -10 -11 -17
-24 -15 -31 4 -12 -49 -35 -90 -39 -26 -2 -28 0 -23 22 6 21 3 24 -21 23 -16
0 -36 -4 -46 -8 -17 -6 -17 -8 -3 -20 20 -17 21 -31 1 -31 -8 0 -15 5 -15 10
0 6 -9 10 -20 10 -27 0 -25 -18 3 -22 28 -4 39 -58 11 -58 -13 0 -14 -3 -5
-13 14 -18 4 -27 -28 -27 -18 0 -22 -3 -13 -9 10 -6 5 -12 -20 -20 l-33 -13
23 -15 c30 -21 28 -33 -7 -33 -17 0 -31 -6 -35 -15 -3 -9 -13 -15 -22 -13 -26
5 -42 -51 -20 -67 22 -17 4 -31 -53 -40 -38 -6 -43 -5 -38 9 8 20 -20 21 -47
1 -20 -15 -14 -35 10 -35 8 0 12 -5 9 -11 -4 -6 -14 -8 -22 -5 -9 4 -22 1 -29
-5 -8 -6 -14 -8 -14 -3 0 5 -4 5 -8 -1 -4 -5 -17 -15 -29 -22 -17 -10 -24 -9
-33 2 -9 11 -6 16 12 26 13 7 34 14 47 15 13 2 26 6 30 10 3 4 -4 5 -16 2 -19
-5 -20 -4 -10 13 6 10 16 19 22 19 5 1 28 19 50 42 40 41 34 42 -17 4 -47 -33
-40 -12 8 28 28 24 49 35 53 28 4 -7 14 -4 30 7 24 18 24 19 5 39 l-19 20 -30
-26 c-16 -14 -29 -27 -27 -29 6 -9 1 -23 -8 -18 -9 5 -51 -17 -76 -40 -13 -13
-6 -38 8 -29 6 4 9 1 6 -6 -2 -8 -13 -18 -24 -24 -10 -6 -22 -15 -26 -21 -5
-6 -8 -5 -8 3 0 7 7 20 15 29 9 8 14 18 11 20 -6 6 -73 -34 -97 -58 -19 -19
-15 -31 7 -22 8 3 14 -1 14 -11 0 -9 -7 -16 -15 -16 -8 0 -15 -5 -15 -11 0 -6
-6 -9 -12 -7 -7 3 -21 -6 -31 -19 -12 -15 -29 -23 -49 -23 -17 0 -28 3 -25 7
8 7 -11 33 -25 33 -5 0 -8 -6 -6 -12 2 -7 -2 -14 -7 -16 -6 -2 -14 -12 -20
-22 -5 -10 -14 -19 -20 -20 -25 -3 -61 -14 -72 -22 -7 -6 -13 -4 -15 4 -4 11
-9 11 -29 0 -13 -7 -47 -18 -76 -25 -49 -13 -52 -13 -59 7 -6 21 -7 21 -11 -1
-3 -18 -13 -23 -51 -28 -26 -3 -57 -7 -70 -9 -26 -4 -42 24 -42 75 0 16 -5 29
-11 29 -8 0 -9 -17 -5 -54 l7 -54 -72 -12 c-39 -7 -86 -14 -105 -15 -18 -2
-42 -6 -52 -9 -24 -8 -148 -25 -252 -36 -80 -7 -86 -7 -102 14 -14 17 -18 18
-18 5 0 -21 -21 -39 -52 -44 -48 -9 -178 -13 -173 -6 2 5 31 16 63 25 32 9 67
22 78 30 17 13 14 14 -25 5 -24 -5 -48 -12 -54 -16 -43 -27 -225 -52 -237 -33
-5 9 -13 9 -26 2 -11 -5 -45 -13 -76 -17 -46 -6 -58 -4 -58 6 0 9 -3 10 -8 3
-12 -18 -102 -11 -113 9 -15 27 -23 145 -12 170 6 12 18 39 27 59 11 25 23 37
34 36 9 -1 42 1 72 5 30 3 133 12 228 18 168 12 174 13 183 35 9 25 3 54 -12
54 -5 0 -14 -11 -19 -25 -5 -14 -12 -25 -16 -25 -3 0 -4 7 0 15 3 8 3 22 0 30
-5 13 -8 13 -21 -1 -10 -10 -25 -13 -39 -10 -20 5 -24 3 -24 -16 0 -16 3 -19
11 -11 9 9 15 8 25 -2 21 -20 17 -25 -16 -25 -19 0 -30 5 -30 14 0 8 -8 17
-18 21 -11 3 -24 17 -30 30 -14 30 -36 34 -27 6 6 -19 -14 -38 -40 -39 -15 -1
-18 -32 -3 -33 28 -3 -50 -17 -99 -18 -44 -1 -53 2 -54 17 -2 23 -2 24 6 43 4
11 0 18 -13 21 -17 5 -19 0 -17 -43 2 -47 2 -49 -25 -49 -16 0 -30 5 -32 12
-2 6 -15 -3 -27 -21 -16 -22 -26 -29 -31 -21 -5 8 -12 7 -24 -5 -9 -9 -16 -21
-16 -26 0 -13 24 -11 37 2 7 7 5 -7 -4 -32 -14 -41 -12 -117 3 -171 5 -16 -2
-18 -48 -18 -29 0 -66 7 -81 15 -23 12 -29 12 -33 1 -3 -8 -15 -11 -29 -8 -14
2 -23 8 -20 12 2 4 -16 6 -40 5 -41 -1 -50 4 -46 27 0 5 -4 8 -9 8 -6 0 -10 8
-10 17 0 22 -39 63 -59 62 -14 0 -14 -1 -1 -6 20 -8 33 -23 48 -56 6 -15 12
-29 12 -31 0 -3 -122 2 -130 5 -8 3 -24 7 -37 8 -15 1 54 76 77 84 8 3 1 6
-16 6 -16 1 -35 6 -42 13 -16 16 -15 65 1 71 6 2 9 7 5 11 -3 4 -16 2 -28 -4
-20 -11 -21 -10 -10 9 6 12 16 21 22 21 6 0 9 3 6 6 -4 3 -17 0 -30 -8 -12 -7
-31 -13 -40 -13 -31 0 -24 -18 12 -31 35 -13 35 -13 5 -11 -40 3 -44 -16 -6
-27 17 -5 31 -13 31 -18 0 -5 -9 -6 -21 -3 -18 6 -20 4 -14 -14 4 -13 2 -21
-5 -21 -6 0 -8 -4 -5 -10 8 -13 55 -13 55 1 0 5 -5 7 -12 3 -7 -4 -8 -3 -4 4
4 7 1 12 -6 13 -10 0 -10 2 -1 6 7 2 20 -3 30 -12 16 -16 16 -18 -2 -31 -27
-20 -42 -17 -84 16 -22 18 -44 28 -53 25 -16 -6 -54 23 -43 33 3 3 11 0 17 -6
18 -18 28 -15 28 7 0 11 4 22 10 26 19 12 -1 35 -26 29 -18 -5 -24 -2 -24 10
0 9 -7 19 -15 23 -17 6 -16 22 8 111 8 28 10 52 6 52 -19 0 -8 31 13 39 42 15
82 -29 53 -58 -13 -14 -15 -13 -16 4 0 11 -3 14 -6 8 -2 -7 -11 -13 -19 -13
-8 0 -14 -7 -14 -15 0 -8 6 -15 14 -15 8 0 17 -7 20 -16 3 -9 11 -12 18 -8 7
5 26 9 43 10 37 2 125 41 125 56 0 5 -7 7 -15 4 -51 -20 -51 -20 -38 -4 11 13
9 15 -15 12 -15 -1 -33 -2 -39 -3 -7 -1 -13 -10 -13 -21 0 -12 -7 -20 -16 -20
-11 0 -14 5 -9 17 17 42 23 73 18 93 -6 21 12 29 20 8 2 -7 8 -9 13 -5 4 4 3
17 -4 27 -7 11 -12 29 -12 40 0 12 -4 18 -10 15 -5 -3 -10 -15 -10 -25 0 -10
-5 -22 -12 -26 -6 -4 -8 -3 -4 4 3 6 2 13 -4 17 -5 3 -10 2 -10 -3 0 -5 -16
-9 -35 -10 -19 -1 -35 2 -35 6 0 8 81 57 95 57 6 0 15 7 22 16 14 19 51 31 58
19 3 -5 -1 -11 -7 -14 -7 -3 -1 -3 13 0 40 8 43 -10 8 -45 -17 -18 -27 -36
-23 -42 4 -8 3 -9 -4 -5 -7 4 -12 3 -12 -3 0 -5 5 -13 11 -17 6 -3 9 -14 6
-24 -3 -13 0 -17 10 -13 10 4 12 14 8 33 -11 52 63 104 100 69 5 -5 33 -9 62
-9 29 0 53 -4 53 -10 0 -5 12 -10 26 -10 14 0 23 -4 19 -10 -4 -6 8 -10 29
-10 52 0 44 -16 -11 -25 l-48 -8 35 -12 c33 -11 33 -12 13 -19 -13 -4 -23 -12
-23 -17 0 -18 22 -8 47 21 24 29 63 41 63 20 0 -6 17 -10 38 -9 20 1 52 -1 70
-4 24 -5 32 -2 32 8 0 8 5 15 11 15 5 0 8 -4 4 -9 -9 -16 13 -22 27 -7 13 12
21 11 62 -7 36 -15 50 -17 55 -8 12 19 35 9 41 -17 l5 -24 24 21 c13 12 27 21
31 21 11 0 20 -28 15 -42 -4 -9 9 -13 45 -14 28 0 45 3 37 8 -7 5 -2 8 16 8
16 0 26 -4 22 -10 -3 -5 -1 -10 4 -10 10 0 15 14 12 33 0 4 4 7 9 7 6 0 10 -6
10 -12 0 -9 3 -9 9 1 7 11 13 7 25 -18 18 -33 40 -41 50 -16 3 8 0 14 -6 12
-6 -1 -13 4 -16 12 -3 8 0 11 7 7 6 -4 11 -2 11 4 0 8 6 7 21 -1 12 -6 32 -9
45 -6 13 2 25 0 26 -5 2 -5 15 -5 32 2 34 12 44 7 26 -15 -8 -10 -9 -15 -1
-15 6 0 11 5 11 10 0 6 5 10 10 10 22 0 8 -30 -27 -59 -25 -20 -49 -31 -70
-31 -17 0 -35 -4 -38 -10 -10 -16 68 -12 103 6 18 8 32 19 32 24 0 5 10 19 23
30 19 19 29 21 80 15 68 -7 79 10 12 21 -25 3 -45 10 -45 14 0 9 94 5 109 -5
6 -3 11 -1 11 4 0 6 10 11 23 11 18 0 19 -2 7 -11 -11 -8 -9 -9 10 -3 14 4 27
11 28 15 2 5 15 9 28 9 16 0 23 -4 18 -11 -4 -7 1 -7 16 1 12 7 36 9 52 6 20
-4 27 -2 22 5 -4 8 4 9 27 4 27 -5 31 -4 19 5 -12 10 -11 11 8 7 12 -3 22 -2
22 2 0 7 115 42 150 46 8 1 28 8 43 15 24 12 30 12 38 -1 7 -12 9 -11 9 3 0
12 13 21 43 28 23 6 62 19 87 30 25 10 81 34 125 51 44 18 90 39 103 47 17 11
25 12 36 2 12 -9 23 -8 50 5 20 10 36 22 36 27 0 5 7 17 16 25 18 19 84 29 84
13 0 -6 11 -3 25 7 13 10 25 27 25 38 0 23 80 71 103 62 8 -3 20 -1 28 4 12 7
12 9 0 9 -11 0 -10 5 4 20 10 11 22 20 27 20 5 0 25 12 43 26 23 17 41 24 57
20 13 -3 17 -3 11 0 -22 10 -14 21 45 60 31 21 67 47 80 58 19 17 22 18 22 3
0 -22 6 -21 41 5 35 27 102 86 76 68 -34 -25 -31 1 4 33 150 135 182 162 192
162 7 0 11 -6 9 -13 -1 -8 2 -11 8 -7 5 3 10 13 10 21 0 16 47 64 63 64 17 0
70 51 89 87 11 19 14 32 9 28 -21 -12 -11 12 15 35 21 21 27 22 34 10 8 -12
12 -11 27 10 10 14 18 35 19 47 1 12 18 45 39 72 40 52 62 63 28 14 -38 -54
-37 -58 5 -14 23 24 42 46 42 48 0 3 14 24 31 48 17 24 28 48 25 54 -11 17 58
116 98 140 20 13 36 27 36 32 0 6 -4 7 -10 4 -5 -3 -10 -1 -10 4 0 6 9 11 20
11 12 0 20 7 20 18 0 14 -2 15 -10 2 -5 -8 -12 -12 -15 -8 -7 6 36 77 48 78 4
0 7 -8 7 -17 0 -15 2 -16 10 -3 6 9 5 17 -3 23 -9 6 -9 10 -1 13 35 15 48 34
50 75 2 55 3 59 14 34 7 -16 9 -14 10 15 1 37 28 108 29 78 1 -9 -3 -28 -9
-43 -6 -15 -6 -24 -1 -20 5 3 15 22 21 41 10 29 10 38 0 44 -9 5 -9 9 -1 14 6
4 11 2 11 -5 0 -8 4 -8 15 1 11 9 14 22 9 44 -5 28 -5 29 8 12 14 -17 16 -14
38 45 13 35 32 78 42 94 10 17 18 42 18 58 0 15 4 27 9 27 5 0 11 8 14 18 3 9
22 60 42 112 20 52 37 102 38 110 1 8 7 24 14 35 10 18 18 47 58 220 31 132
45 202 50 245 7 59 24 110 37 110 6 0 7 -7 3 -17z m-8326 -19 c57 -17 99 -51
125 -101 27 -53 21 -80 -19 -93 -40 -13 -67 -3 -101 38 -42 51 -202 37 -238
-20 -8 -14 -7 -22 7 -35 15 -16 19 -16 27 -3 5 8 10 10 10 4 0 -8 39 -14 113
-19 177 -12 237 -52 245 -164 4 -66 -17 -105 -86 -158 -23 -18 -42 -35 -42
-38 1 -3 0 -9 -3 -13 -2 -4 -2 -1 0 7 2 10 -3 11 -19 6 -13 -4 -53 -11 -91
-17 -56 -8 -77 -7 -122 7 -68 22 -128 72 -149 126 -16 39 -16 43 0 60 11 12
31 19 54 19 30 0 45 -7 74 -37 36 -37 38 -38 110 -38 64 0 77 3 109 28 37 28
40 50 9 62 -9 4 -71 11 -138 15 -125 9 -163 19 -202 54 -26 23 -56 116 -47
146 4 11 2 20 -4 20 -5 0 6 17 24 38 19 20 35 40 35 43 0 4 23 19 51 34 87 45
180 55 268 29z m4738 -6 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z
m378 -46 c-3 -3 -11 3 -19 13 -10 15 -11 23 -2 37 10 16 12 15 19 -13 5 -17 6
-34 2 -37z m3485 58 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6
0 11 -4 11 -10z m-8900 -19 c0 -12 -20 -25 -27 -18 -7 7 6 27 18 27 5 0 9 -4
9 -9z m6187 -69 c-13 -29 -15 -25 -9 28 4 38 6 41 12 20 4 -14 2 -35 -3 -48z
m393 53 c0 -8 -4 -15 -9 -15 -13 0 -22 16 -14 24 11 11 23 6 23 -9z m80 -1 c0
-8 -4 -13 -9 -10 -5 4 -11 2 -13 -2 -1 -5 -15 -12 -30 -15 -23 -6 -25 -4 -21
14 4 16 11 19 29 15 12 -3 26 -2 29 4 9 15 15 12 15 -6z m-980 -2 c0 -13 -12
-22 -22 -16 -10 6 -1 24 13 24 5 0 9 -4 9 -8z m480 -12 c0 -5 -7 -7 -15 -4 -8
4 -12 10 -9 15 6 11 24 2 24 -11z m330 15 c0 -2 -10 -9 -22 -15 -22 -11 -22
-10 -4 4 21 17 26 19 26 11z m-680 -14 c0 -12 -20 -25 -27 -18 -7 7 6 27 18
27 5 0 9 -4 9 -9z m482 -23 c-7 -7 -12 -8 -12 -2 0 14 12 26 19 19 2 -3 -1
-11 -7 -17z m-1715 6 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z
m4240 0 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-2875 -26 c-7
-7 -12 -8 -12 -2 0 14 12 26 19 19 2 -3 -1 -11 -7 -17z m-525 -10 c-3 -8 -6
-5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m863 2 c0 -5 -5 -10 -11 -10 -5 0
-7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m330 -20 c0 -5 -7 -10 -15 -10 -8
0 -15 5 -15 10 0 6 7 10 15 10 8 0 15 -4 15 -10z m-670 -10 c0 -5 -9 -10 -20
-10 -11 0 -20 5 -20 10 0 6 9 10 20 10 11 0 20 -4 20 -10z m-1090 -10 c0 -5
-4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m1499 -4
c9 -10 8 -15 -4 -20 -10 -4 -15 1 -15 14 0 24 4 25 19 6z m-1309 -13 c0 -10
-5 -25 -10 -33 -8 -11 -10 -7 -8 18 2 33 18 46 18 15z m1094 2 c-6 -16 -24
-21 -24 -7 0 11 11 22 21 22 5 0 6 -7 3 -15z m-979 -25 c3 -6 -1 -7 -9 -4 -18
7 -21 14 -7 14 6 0 13 -4 16 -10z m404 -7 c0 -5 -4 -17 -9 -28 -8 -18 -9 -17
-9 8 -1 15 4 27 9 27 6 0 10 -3 9 -7z m-2929 -12 c0 -5 -11 -8 -25 -7 -33 1
-33 16 0 16 14 0 25 -4 25 -9z m2720 -1 c0 -5 -18 -15 -40 -21 -23 -7 -42 -20
-45 -31 -8 -24 -25 -23 -25 3 0 43 18 59 65 59 25 0 45 -4 45 -10z m-2110 -24
c0 -11 -19 -15 -25 -6 -3 5 1 10 9 10 9 0 16 -2 16 -4z m1887 -2 c-3 -3 -12
-4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m3753 -19 c-13 -14 -26 -23 -28
-20 -5 5 36 45 46 45 3 0 -5 -11 -18 -25z m-5300 0 c0 -8 -4 -15 -10 -15 -5 0
-10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z m-380 -5 c0 -5 -8 -10 -17 -10
-15 0 -16 2 -3 10 19 12 20 12 20 0z m5475 0 c3 -5 1 -10 -4 -10 -6 0 -11 5
-11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m-6430 -20 c-10 -11 -20 -18 -23 -15
-7 6 18 35 31 35 5 0 2 -9 -8 -20z m835 10 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4
10 3 6 8 10 11 10 2 0 4 -4 4 -10z m5750 4 c0 -3 -12 -9 -28 -15 -30 -10 -56
-57 -46 -82 7 -20 -12 -57 -30 -57 -8 0 -16 -7 -20 -15 -3 -8 -12 -15 -20 -15
-7 0 -16 -7 -20 -15 -3 -8 -1 -15 4 -15 16 0 12 -60 -5 -66 -8 -4 -12 -10 -9
-15 8 -14 -6 -50 -18 -43 -10 6 -7 61 6 109 17 66 24 80 38 80 24 0 58 35 58
58 0 34 23 86 39 90 25 7 51 8 51 1z m-8970 -34 c0 -11 -4 -20 -10 -20 -5 0
-10 9 -10 20 0 11 5 20 10 20 6 0 10 -9 10 -20z m3185 10 c-3 -5 -11 -10 -16
-10 -6 0 -7 5 -4 10 3 6 11 10 16 10 6 0 7 -4 4 -10z m-465 -10 c0 -5 -4 -10
-10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m2088 -80 c-14
-22 -48 -27 -48 -7 0 20 10 27 37 27 21 0 22 -2 11 -20z m-1957 -33 c-27 -42
-45 -48 -31 -11 14 37 30 55 42 48 5 -3 0 -20 -11 -37z m903 21 c-18 -58 -25
-70 -39 -64 -8 3 -19 -3 -24 -12 -9 -14 -10 -11 -11 16 0 20 5 31 12 30 7 -2
14 7 16 20 2 16 8 22 18 18 8 -3 14 -1 14 4 0 6 5 10 11 10 6 0 8 -9 3 -22z
m-3052 -10 c-7 -7 -12 -8 -12 -2 0 14 12 26 19 19 2 -3 -1 -11 -7 -17z m-685
-8 c1 -11 -2 -20 -8 -20 -5 0 -9 9 -9 20 0 11 4 20 8 20 4 0 8 -9 9 -20z
m2403 7 c0 -2 -9 -21 -20 -43 -18 -36 -19 -37 -20 -12 0 36 9 58 26 58 8 0 14
-1 14 -3z m6604 -27 c-9 -16 -19 -30 -24 -30 -4 0 -11 -11 -14 -24 -3 -14 -20
-30 -42 -41 -42 -20 -52 -15 -31 16 9 11 14 26 12 33 -5 13 73 75 97 75 15 1
15 -2 2 -29z m-4944 3 c-1 -18 -18 -35 -24 -24 -4 6 -4 16 -1 21 7 12 25 13
25 3z m-2336 -35 c11 -10 9 -14 -8 -23 -12 -6 -31 -18 -43 -25 -27 -18 -41 -6
-25 20 10 16 10 20 0 21 -7 1 1 5 17 9 44 11 45 11 59 -2z m1846 -8 c0 -5 -5
-10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m80 2 c0 -5 -7
-17 -15 -28 -15 -19 -20 -8 -9 20 6 17 24 22 24 8z m-377 -9 c-7 -2 -13 -12
-13 -20 0 -9 -3 -14 -6 -10 -10 10 4 37 18 36 10 0 10 -2 1 -6z m-2583 -22 c0
-11 -31 -24 -39 -16 -3 3 1 10 9 15 19 12 30 12 30 1z m3305 -1 c3 -5 1 -10
-4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m-365 -41 c0 -5 -6
-9 -14 -9 -8 0 -22 -7 -30 -16 -12 -11 -16 -12 -16 -2 0 18 12 34 30 40 17 6
30 1 30 -13z m-2580 -9 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10
2 0 4 -4 4 -10z m1375 0 c-37 -16 -77 -59 -85 -92 l-8 -33 -1 33 c-2 56 40
101 94 101 l20 0 -20 -9z m490 0 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2
10 4 10 3 0 8 -4 11 -10z m6020 -10 c3 -5 -1 -10 -10 -10 -9 0 -13 5 -10 10 3
6 8 10 10 10 2 0 7 -4 10 -10z m-8345 -10 c0 -5 -11 -12 -25 -16 -14 -3 -25
-10 -25 -15 0 -5 -5 -9 -11 -9 -18 0 -4 24 22 37 29 15 39 16 39 3z m40 0 c0
-5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m8280
-20 c0 -6 8 -5 20 2 30 19 36 1 10 -28 -22 -23 -25 -23 -31 -7 -7 17 -8 17 -8
1 -1 -21 -21 -24 -21 -4 0 22 11 46 21 46 5 0 9 -5 9 -10z m-7970 -10 c0 -5
-5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m1785 0 c3 -5
1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m570 0 c-3 -5
-14 -10 -23 -10 -15 0 -15 2 -2 10 20 13 33 13 25 0z m5441 -1 c4 -7 -2 -19
-13 -28 -11 -9 -23 -24 -26 -33 -7 -19 -27 -25 -27 -8 0 6 -7 10 -15 10 -27 0
-16 17 16 24 19 3 28 10 23 17 -3 6 0 15 7 20 19 12 26 11 35 -2z m-7093 -5
c7 -8 -16 -33 -25 -28 -9 6 1 34 11 34 4 0 10 -3 14 -6z m564 -36 c-3 -7 -5
-2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m423 2 c0 -5 -8 -10 -17 -10 -15 0
-16 2 -3 10 19 12 20 12 20 0z m-137 -17 c8 -7 -21 -43 -35 -43 -12 0 -10 24
4 38 14 14 22 15 31 5z m-2093 -13 c0 -5 -4 -10 -10 -10 -5 0 -10 -7 -10 -15
0 -8 -7 -15 -15 -15 -9 0 -21 -11 -27 -25 -6 -14 -20 -25 -30 -25 -19 0 -19 1
2 30 35 49 90 86 90 60z m8405 -28 c-10 -2 -14 -10 -11 -19 4 -11 2 -13 -9 -9
-8 3 -15 2 -16 -2 0 -4 -2 -19 -4 -34 -2 -17 1 -28 8 -28 7 0 12 -11 12 -24 0
-13 -4 -22 -8 -19 -4 2 -5 -6 -2 -20 8 -31 -13 -47 -30 -24 -7 10 -11 35 -9
57 1 22 -2 42 -7 46 -6 3 -4 11 5 17 8 7 16 23 18 37 2 14 9 24 16 23 6 -2 12
6 12 17 0 19 1 19 20 2 18 -15 19 -17 5 -20z m-6208 12 c-3 -3 -12 -4 -19 -1
-8 3 -5 6 6 6 11 1 17 -2 13 -5z m-997 -14 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4
10 3 6 8 10 11 10 2 0 4 -4 4 -10z m7340 7 c0 -1 -9 -10 -20 -20 -19 -16 -20
-16 -20 3 0 13 7 20 20 20 11 0 20 -1 20 -3z m-8420 -32 c-7 -9 -15 -13 -17
-11 -7 7 7 26 19 26 6 0 6 -6 -2 -15z m300 5 c-8 -5 -19 -10 -25 -10 -5 0 -3
5 5 10 8 5 20 10 25 10 6 0 3 -5 -5 -10z m140 0 c0 -5 -2 -10 -4 -10 -3 0 -8
5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m-183 -16 c4 -15 -14 -37 -25 -30
-13 8 -3 48 11 43 6 -2 12 -7 14 -13z m267 0 c9 -24 2 -26 -12 -4 -7 11 -8 20
-3 20 5 0 12 -7 15 -16z m396 7 c0 -6 -4 -13 -10 -16 -5 -3 -10 1 -10 9 0 9 5
16 10 16 6 0 10 -4 10 -9z m-1273 -7 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1
17 -2 13 -5z m8499 -19 c4 -8 11 -15 16 -15 34 0 54 -102 22 -114 -9 -3 -21
-6 -27 -5 -7 0 -2 4 11 10 19 7 23 15 20 46 -4 53 -19 50 -48 -10 -14 -29 -31
-51 -37 -49 -7 1 -13 -4 -13 -10 -1 -8 -7 -5 -15 6 -13 17 -12 22 5 46 25 36
25 50 0 50 -11 0 -20 5 -20 11 0 6 7 9 15 5 8 -3 15 -1 15 4 0 12 29 40 41 40
5 0 12 -7 15 -15z m-7766 -10 c-7 -9 -15 -13 -19 -10 -3 3 1 10 9 15 21 14 24
12 10 -5z m717 -30 c14 -5 14 -7 -3 -20 -28 -21 -44 -18 -44 10 0 17 4 23 15
20 8 -3 22 -7 32 -10z m-1087 -15 c-6 -11 -12 -20 -15 -20 -2 0 -11 -3 -20 -6
-14 -6 -14 -3 -4 20 7 15 20 26 31 26 16 0 17 -3 8 -20z m-135 -50 c-3 -5 -10
-10 -16 -10 -5 0 -9 5 -9 10 0 6 7 10 16 10 8 0 12 -4 9 -10z m749 -31 c-12
-21 -73 -40 -92 -30 -9 6 -15 11 -12 11 3 0 23 7 45 15 49 17 68 19 59 4z
m383 -31 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m7253 12 c0 -5
-10 -10 -22 -10 -19 0 -20 2 -8 10 19 13 30 13 30 0z m-7142 -25 c-1 -14 -8
-25 -15 -25 -7 0 -13 -4 -13 -10 0 -5 -9 -10 -21 -10 -26 0 -22 32 5 36 11 1
23 10 28 18 13 23 18 20 16 -9z m6792 -15 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4
10 3 6 8 10 11 10 2 0 4 -4 4 -10z m-6995 -62 c-6 -27 -5 -30 11 -24 16 6 17
5 6 -6 -11 -11 -16 -10 -28 6 -19 26 -18 56 2 56 12 0 14 -7 9 -32z m6975 12
c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m400
-48 c0 -4 -7 -17 -15 -29 -8 -12 -12 -30 -9 -40 6 -16 5 -16 -9 -3 -9 8 -19
30 -23 48 -6 31 -5 32 25 32 17 0 31 -3 31 -8z m-122 -8 c14 -4 22 -14 22 -29
0 -18 -7 -24 -30 -27 -21 -3 -34 0 -40 11 -7 14 -11 13 -29 -3 -21 -19 -21
-19 -21 2 0 12 6 22 14 22 8 0 16 7 20 15 6 16 26 19 64 9z m152 -3 c0 -6 -4
-13 -10 -16 -5 -3 -10 1 -10 9 0 9 5 16 10 16 6 0 10 -4 10 -9z m-220 -81 c0
-5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m-6976
-12 c5 -10 7 -10 12 0 3 6 9 12 14 12 14 0 -12 -45 -37 -64 -20 -15 -25 -15
-38 -3 -13 13 -13 16 1 21 9 3 14 12 11 20 -10 25 26 38 37 14z m-115 -31 c8
-10 7 -18 -3 -32 -12 -17 -15 -17 -29 -4 -10 11 -12 20 -5 32 11 20 22 21 37
4z m7031 -21 c0 -2 -7 -7 -16 -10 -8 -3 -12 -2 -9 4 6 10 25 14 25 6z m-6815
-52 c-9 -16 -14 -21 -11 -11 3 10 6 28 6 40 0 15 3 18 11 10 8 -8 6 -19 -6
-39z m6885 21 c0 -8 -7 -15 -16 -15 -14 0 -14 3 -4 15 7 8 14 15 16 15 2 0 4
-7 4 -15z m-360 -37 c-1 -18 -28 -53 -36 -46 -5 6 24 58 32 58 2 0 4 -6 4 -12z
m-6730 -38 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4
-10z m6890 0 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10
-4 10 -10z m-6705 -10 c-3 -5 -11 -10 -16 -10 -6 0 -7 5 -4 10 3 6 11 10 16
10 6 0 7 -4 4 -10z m6625 -15 c0 -14 -4 -25 -9 -25 -4 0 -6 11 -3 25 2 14 6
25 8 25 2 0 4 -11 4 -25z m-6674 -6 c3 -6 0 -15 -7 -20 -22 -14 -28 -11 -22
11 6 22 18 26 29 9z m224 -4 c-7 -8 -18 -15 -25 -15 -7 0 -16 -11 -19 -25 -4
-14 -11 -25 -16 -25 -6 0 -10 11 -10 25 0 15 6 25 14 25 7 0 19 7 26 15 7 8
19 15 27 15 13 0 13 -3 3 -15z m-80 -43 c0 -19 -14 -28 -80 -55 -31 -13 -81
-35 -102 -45 -5 -2 -8 1 -8 6 0 6 26 25 58 43 31 18 71 41 87 50 39 23 45 23
45 1z m6460 -26 c0 -2 -7 -9 -15 -16 -13 -11 -14 -10 -9 4 5 14 24 23 24 12z
m287 -42 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-1 -66 c-3
-24 -9 -33 -21 -31 -13 1 -15 -4 -11 -23 5 -18 2 -24 -10 -24 -14 0 -14 -2 -3
-16 12 -14 14 -14 25 0 18 26 26 18 8 -9 -15 -23 -17 -23 -40 -8 -28 18 -43
11 -24 -12 11 -13 10 -19 -5 -35 -12 -14 -20 -16 -29 -8 -14 14 -16 89 -1 80
6 -3 6 0 -1 9 -6 8 -14 29 -17 47 -5 24 -3 32 8 32 8 0 15 5 15 10 0 15 77 12
83 -2 3 -8 6 -6 6 5 1 9 5 17 11 17 5 0 8 -15 6 -32z m-6748 -3 c0 -16 -8 -25
-23 -29 -20 -5 -22 -11 -21 -69 1 -59 0 -63 -20 -59 -16 3 -20 9 -17 23 4 13
0 19 -10 19 -9 0 -22 14 -31 30 -10 22 -11 30 -2 30 6 0 16 14 21 32 7 22 16
32 32 33 13 1 25 4 27 6 3 3 14 5 24 6 14 1 20 -5 20 -22z m-148 5 c0 -5 -2
-10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m267 4 c-3 -3
-12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m79 -51 c-10 -10 -19 5 -10 18
6 11 8 11 12 0 2 -7 1 -15 -2 -18z m0 -49 c-3 -9 -6 -22 -6 -30 0 -8 -4 -14
-10 -14 -5 0 -10 14 -10 30 0 21 5 30 16 30 11 0 14 -5 10 -16z m-36 -19 c0
-8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z m6906
0 c-11 -8 -27 -15 -35 -15 -11 0 -10 4 5 15 10 8 26 14 34 14 11 0 10 -3 -4
-14z m-6779 -17 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m5894
-40 c-12 -12 -21 -25 -21 -30 0 -4 -4 -8 -10 -8 -16 0 -11 13 18 48 14 18 28
28 30 23 2 -6 -6 -20 -17 -33z m-6031 15 c-8 -4 -23 -17 -33 -30 -22 -28 -37
-30 -37 -5 0 20 33 41 65 41 14 -1 16 -2 5 -6z m11 -46 c-8 -8 -11 -7 -11 4 0
9 3 19 7 23 4 4 9 2 11 -4 3 -7 -1 -17 -7 -23z m6519 14 c0 -12 -20 -25 -27
-18 -7 7 6 27 18 27 5 0 9 -4 9 -9z m-6615 -10 c3 -5 1 -12 -5 -16 -5 -3 -10
1 -10 9 0 18 6 21 15 7z m320 -10 c3 -5 1 -12 -5 -16 -5 -3 -10 1 -10 9 0 18
6 21 15 7z m-304 -72 c-6 -24 -9 -28 -10 -12 -1 24 9 56 16 50 2 -3 0 -20 -6
-38z m203 26 c19 -15 19 -15 -1 -15 -12 0 -29 -7 -37 -16 -9 -8 -16 -13 -16
-9 0 3 -9 1 -21 -5 -33 -18 -35 -2 -4 30 32 33 50 37 79 15z m-237 -47 c-3 -7
-5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m110 -80 c-3 -7 -5 -2 -5 12 0
14 2 19 5 13 2 -7 2 -19 0 -25z m347 1 c-3 -5 -10 -7 -15 -3 -5 3 -7 10 -3 15
3 5 10 7 15 3 5 -3 7 -10 3 -15z m6395 8 c-8 -7 -18 -20 -22 -29 -9 -24 -27
-23 -27 2 0 11 7 20 15 20 8 0 15 5 15 10 0 6 8 10 18 10 15 -1 15 -2 1 -13z
m-6664 -51 c-10 -20 -21 -36 -26 -36 -15 0 -10 20 5 26 8 3 12 12 9 20 -8 22
5 50 18 37 8 -8 6 -22 -6 -47z m245 23 c0 -5 -4 -9 -10 -9 -5 0 -10 7 -10 16
0 8 5 12 10 9 6 -3 10 -10 10 -16z m-270 -9 c0 -11 -50 -34 -57 -26 -2 2 3 11
12 20 18 18 45 21 45 6z m134 -6 c16 -41 -2 -88 -29 -78 -7 3 -29 -10 -49 -29
-38 -36 -55 -33 -35 5 20 37 59 66 81 60 15 -4 19 -1 16 9 -3 8 -11 13 -17 12
-12 -3 -15 19 -4 30 11 12 31 7 37 -9z m31 6 c9 -14 99 -12 113 2 9 9 12 9 12
0 0 -7 -4 -12 -10 -12 -21 0 -9 -19 18 -28 15 -6 21 -11 13 -11 -8 -1 -20 -9
-28 -18 -14 -17 -14 -17 -9 0 6 17 1 18 -60 14 -55 -4 -65 -3 -60 10 3 8 1 23
-4 34 -11 20 3 28 15 9z m5912 4 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17
-2 13 -5z m-5677 -13 c0 -5 -7 -11 -15 -15 -15 -5 -20 5 -8 17 9 10 23 9 23
-2z m15 -65 c-10 -14 -22 -26 -27 -26 -5 0 -6 -4 -3 -10 3 -5 1 -10 -4 -10 -6
0 -11 7 -11 15 0 8 5 15 11 15 5 0 8 4 4 9 -3 5 4 12 15 16 11 3 20 11 20 17
0 6 3 9 6 6 3 -4 -2 -18 -11 -32z m5295 4 c0 -13 -5 -18 -15 -14 -12 5 -13 10
-4 20 15 19 19 18 19 -6z m732 12 c-8 -5 -10 -16 -6 -28 4 -10 4 -16 0 -12 -5
4 -21 -7 -37 -25 -21 -23 -32 -28 -40 -20 -9 9 0 22 32 52 24 22 49 41 54 41
6 0 4 -4 -3 -8z m153 -33 c-4 -5 -13 -9 -20 -9 -9 0 -12 -6 -9 -15 4 -9 0 -15
-9 -15 -8 0 -17 -6 -20 -12 -2 -7 -8 -10 -12 -5 -11 9 55 78 67 71 4 -3 6 -10
3 -15z m-5955 1 c-20 -13 -33 -13 -25 0 3 6 14 10 23 10 15 0 15 -2 2 -10z
m-142 -38 c2 -12 -1 -22 -7 -22 -6 0 -11 4 -11 9 0 5 -3 17 -7 27 -4 12 -2 15
8 12 8 -2 15 -14 17 -26z m5157 9 c-3 -6 -11 -11 -17 -11 -6 0 -6 6 2 15 14
17 26 13 15 -4z m-5265 -21 c0 -5 -18 -10 -39 -10 -30 0 -46 -7 -68 -28 -31
-31 -54 -43 -93 -48 -14 -2 -34 -5 -45 -8 -11 -3 -27 -1 -35 4 -13 8 -13 10 2
10 9 0 18 5 20 11 6 19 161 77 211 78 26 0 47 -3 47 -9z m70 -18 c0 -13 -12
-22 -22 -16 -10 6 -1 24 13 24 5 0 9 -4 9 -8z m-320 -12 c0 -5 -2 -10 -4 -10
-3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m532 -32 c-8 -18 -17 -27
-19 -21 -5 15 14 53 27 53 4 0 1 -14 -8 -32z m-576 12 c8 0 14 -4 14 -10 0 -5
-13 -10 -30 -10 -21 0 -30 5 -30 16 0 11 5 14 16 10 9 -3 22 -6 30 -6z m489
-10 c4 -6 0 -21 -9 -34 -16 -26 -12 -38 10 -29 10 4 14 -3 14 -21 0 -33 -3
-42 -13 -38 -4 1 -3 -7 3 -18 13 -25 2 -25 -28 0 -19 16 -23 27 -20 61 2 27
-1 44 -9 46 -8 3 -13 -6 -14 -24 -1 -17 -3 -21 -6 -10 -6 21 -17 22 -33 2 -19
-23 -30 -18 -30 15 0 17 -4 30 -10 30 -5 0 -10 4 -10 8 0 5 25 8 56 8 31 -1
61 2 67 6 16 11 25 10 32 -2z m5880 -10 c-3 -5 -10 -10 -16 -10 -5 0 -9 5 -9
10 0 6 7 10 16 10 8 0 12 -4 9 -10z m-50 -7 c-11 -3 -24 -12 -28 -19 -6 -11
-13 -12 -29 -3 -19 10 -20 9 -14 -17 5 -22 4 -25 -4 -14 -9 13 -14 10 -36 -15
-23 -28 -24 -28 -15 -5 5 14 12 37 15 53 6 26 8 27 69 26 34 -1 53 -4 42 -6z
m-5605 -13 c-8 -5 -19 -10 -25 -10 -5 0 -3 5 5 10 8 5 20 10 25 10 6 0 3 -5
-5 -10z m4845 -19 c-6 -19 8 -68 19 -63 19 7 46 0 46 -13 0 -9 -6 -12 -15 -9
-20 8 -19 -6 3 -22 15 -12 15 -15 2 -24 -34 -22 -66 -11 -94 32 l-28 43 26 22
c14 12 26 29 26 38 0 8 5 15 11 15 6 0 8 -8 4 -19z m-5325 -5 c0 -8 -4 -17 -9
-21 -12 -7 -24 12 -16 25 9 15 25 12 25 -4z m5510 4 c0 -5 -4 -10 -10 -10 -5
0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-5697 -7 c-7 -2 -19 -2 -25
0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m82 -3 c-3 -5 -14 -10 -23 -10 -15 0 -15
2 -2 10 20 13 33 13 25 0z m405 -6 c0 -8 -4 -12 -10 -9 -5 3 -10 10 -10 16 0
5 5 9 10 9 6 0 10 -7 10 -16z m5795 -4 c-3 -5 -12 -10 -18 -10 -7 0 -6 4 3 10
19 12 23 12 15 0z m-6141 -33 c-4 -12 -13 -27 -21 -32 -23 -17 -11 -69 17 -73
13 -2 33 -10 46 -18 19 -13 22 -13 27 1 8 19 53 20 61 0 9 -25 25 -17 22 12
-2 15 -9 29 -17 31 -43 12 -45 13 -25 28 27 19 35 18 45 -8 9 -22 9 -22 17 -4
5 13 3 21 -4 24 -13 4 -17 42 -4 42 5 0 26 -16 48 -35 21 -19 44 -35 50 -35 6
0 29 -8 50 -17 22 -9 44 -16 51 -15 6 2 14 -2 17 -6 3 -5 24 -8 46 -6 49 4 54
-11 6 -19 -42 -7 -102 6 -125 27 -24 21 -31 20 -31 -6 0 -20 -1 -21 -20 -3
-11 10 -21 16 -23 14 -1 -2 -9 -17 -17 -32 -7 -15 -19 -26 -27 -23 -24 10 -24
-13 1 -29 13 -9 29 -29 35 -45 9 -28 9 -29 -15 -18 -27 12 -47 2 -24 -12 17
-11 13 -15 -20 -24 -19 -6 -22 -5 -10 3 13 9 13 12 1 20 -8 5 -20 7 -28 4 -15
-6 -63 33 -63 51 0 14 -70 76 -86 76 -6 0 -14 7 -18 15 -3 8 -12 15 -21 15 -8
0 -15 5 -15 12 0 7 -12 24 -26 39 -29 30 -25 49 9 49 12 1 36 7 52 14 37 16
48 11 39 -17z m68 11 c-7 -7 -12 -8 -12 -2 0 6 3 14 7 17 3 4 9 5 12 2 2 -3
-1 -11 -7 -17z m329 0 c21 -23 24 -38 9 -38 -5 0 -16 14 -25 30 -18 35 -10 39
16 8z m-534 6 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m283 -4
c0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 7 10 15 10 8 0 15 -4 15 -10z
m5438 -15 c7 -16 17 -23 27 -19 8 3 15 1 15 -5 0 -6 -4 -11 -9 -11 -15 0 -39
-57 -31 -70 5 -7 15 -10 24 -7 9 4 16 2 16 -3 0 -6 -9 -10 -21 -10 -12 0 -30
-11 -40 -24 -10 -13 -28 -26 -40 -29 -22 -6 -23 6 -4 58 7 20 6 20 -9 1 -9
-11 -16 -14 -16 -8 0 7 -5 12 -11 12 -7 0 -9 -10 -7 -25 3 -14 1 -25 -3 -25
-5 0 -9 5 -9 11 0 6 -7 3 -16 -5 -19 -19 -17 -19 -59 -2 -19 8 -35 21 -35 28
-1 9 -4 8 -13 -3 -7 -9 -18 -14 -24 -12 -7 3 -13 -1 -13 -9 0 -8 -4 -7 -11 5
-12 21 -29 22 -29 1 0 -8 -5 -12 -10 -9 -8 5 -8 11 0 20 7 9 6 16 -5 25 -8 7
-15 21 -15 32 0 17 5 18 53 12 28 -4 66 -15 84 -25 46 -26 98 -16 118 24 8 16
15 36 15 44 0 52 58 73 78 28z m17 15 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0
6 2 10 4 10 3 0 8 -4 11 -10z m-5245 -10 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10
10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-283 -25 c-9 -8 -17 -20 -20 -27 -2 -8
-8 -9 -13 -4 -5 5 -4 11 4 13 6 3 12 11 12 19 0 8 7 14 16 14 14 0 14 -2 1
-15z m5213 -5 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10
-4 10 -10z m-4907 -7 c4 -3 2 -12 -4 -20 -7 -8 -8 -13 -1 -13 5 0 13 8 16 17
6 15 8 15 23 0 13 -12 14 -19 5 -30 -12 -14 -62 -12 -62 2 0 3 -3 16 -6 29 -5
15 -2 22 8 22 8 0 18 -3 21 -7z m147 -8 c0 -8 -4 -15 -8 -15 -5 0 -9 -15 -10
-32 -1 -18 -6 -46 -11 -63 -8 -27 -9 -25 -10 22 -1 56 12 103 29 103 5 0 10
-7 10 -15z m-60 -25 c0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 7 10 15 10 8
0 15 -4 15 -10z m5280 6 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10
11 6 0 10 -2 10 -4z m-5730 -16 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5
10 10 10 6 0 10 -4 10 -10z m5880 0 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6
8 10 11 10 2 0 4 -4 4 -10z m114 -29 c-16 -14 -31 -22 -33 -17 -5 11 42 55 53
48 4 -2 -5 -16 -20 -31z m-5085 -37 c-21 -39 -29 -27 -11 16 11 24 18 33 20
23 2 -8 -2 -26 -9 -39z m4771 38 c0 -14 -69 -76 -77 -69 -3 4 -1 7 5 7 6 0 14
10 18 22 10 31 54 64 54 40z m-4956 -18 c5 -13 2 -15 -14 -11 -11 3 -20 1 -20
-5 0 -5 -5 -6 -10 -3 -6 3 -13 -1 -16 -9 -9 -24 -26 -19 -22 6 3 21 13 26 69
37 4 0 10 -6 13 -15z m-107 0 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2
13 -5z m4393 -3 c0 -5 -7 -12 -16 -15 -14 -5 -15 -4 -4 9 14 17 20 19 20 6z
m-4774 -38 c-4 -4 -11 -1 -16 7 -16 25 -11 35 7 17 9 -9 13 -20 9 -24z m328
22 c3 -8 1 -15 -5 -15 -6 0 -8 -4 -4 -9 3 -5 1 -12 -5 -16 -5 -3 -10 0 -11 7
0 7 -4 4 -9 -7 -7 -17 -9 -18 -9 -2 -1 18 20 57 30 57 4 0 9 -7 13 -15z m5364
-24 c-13 -14 -24 -28 -25 -30 -2 -2 -6 -2 -9 2 -10 9 36 68 48 61 5 -4 0 -17
-14 -33z m-333 20 c3 -5 1 -12 -5 -16 -5 -3 -10 1 -10 9 0 18 6 21 15 7z m221
-5 c7 -18 -25 -56 -47 -56 -13 0 -16 6 -12 23 11 44 47 64 59 33z m-607 -3
c-3 -20 2 -32 16 -35 10 -2 8 -6 -9 -14 -20 -9 -23 -8 -19 6 3 10 0 21 -6 24
-16 10 -13 26 4 26 8 0 14 -3 14 -7z m-4609 -19 c0 -8 4 -13 9 -9 5 3 22 -6
38 -20 21 -18 24 -24 11 -25 -11 0 -15 -6 -11 -20 4 -14 0 -20 -11 -20 -9 0
-19 -7 -22 -15 -5 -11 -10 -13 -19 -5 -15 13 -40 5 -82 -27 -41 -30 -46 -8 -7
28 15 13 33 39 40 57 8 17 19 32 24 32 6 0 10 9 10 20 0 11 5 20 10 20 6 0 10
-7 10 -16z m-371 -18 c9 -10 8 -15 -4 -20 -10 -4 -15 1 -15 14 0 24 4 25 19 6z
m5245 0 c-3 -7 -15 -18 -27 -24 -20 -10 -19 -11 12 -11 27 -1 32 -4 26 -18 -5
-15 -4 -16 6 -5 7 6 17 12 22 12 5 0 2 -7 -7 -15 -17 -17 -56 -20 -56 -5 0 7
-6 7 -20 0 -23 -12 -27 -35 -5 -26 8 3 15 1 15 -4 0 -11 -54 -21 -95 -18 -20
2 -29 -1 -27 -9 1 -9 -10 -13 -33 -13 -29 0 -37 5 -45 26 -13 34 -13 37 5 31
8 -4 17 -2 20 3 4 6 -5 10 -19 10 -14 0 -26 4 -26 10 0 18 40 30 98 29 32 -1
69 4 83 11 13 7 31 17 39 21 24 12 40 10 34 -5z m-4311 -35 c3 -13 -1 -21 -14
-24 -11 -3 -19 -14 -19 -26 0 -32 3 -35 40 -38 40 -5 45 -8 35 -23 -5 -8 -11
-8 -21 1 -11 9 -14 6 -16 -17 -1 -16 -1 -40 -1 -54 1 -14 -1 -19 -4 -13 -3 10
-10 10 -25 2 -20 -11 -20 -11 3 -22 12 -7 24 -18 27 -25 3 -11 0 -12 -17 -2
-18 9 -26 7 -47 -9 -14 -11 -20 -22 -14 -26 5 -3 10 -1 10 4 0 6 7 11 16 11
10 0 14 -6 11 -15 -4 -8 -2 -17 4 -21 6 -3 13 3 15 13 3 11 30 31 65 48 62 29
81 45 55 45 -9 0 -16 -4 -16 -10 0 -5 -3 -9 -7 -8 -5 1 -18 -3 -30 -10 -13 -7
-24 -8 -28 -3 -3 5 5 13 17 17 25 8 48 28 48 42 0 5 7 15 16 23 8 7 13 21 11
31 -4 15 -1 17 21 12 15 -4 48 -19 73 -35 25 -16 52 -29 59 -29 7 0 10 -3 7
-6 -4 -4 14 -17 40 -29 32 -16 47 -29 48 -44 2 -31 -10 -36 -92 -40 -22 -1
-33 -6 -33 -16 0 -9 6 -12 15 -9 8 4 15 1 15 -4 0 -12 -54 -25 -86 -22 -47 5
-47 5 -40 -82 8 -96 13 -118 28 -118 9 0 6 54 -8 130 -7 45 7 53 98 56 46 1
89 3 96 3 21 2 3 21 -19 21 -10 0 -19 5 -19 10 0 9 33 15 71 12 10 -1 22 11
29 29 9 20 16 26 20 18 5 -6 15 -9 23 -6 11 4 13 0 9 -15 -4 -17 -1 -19 16
-14 11 4 29 3 39 -3 10 -5 33 -15 50 -21 18 -7 30 -17 26 -23 -4 -6 3 -8 20
-3 18 4 29 1 35 -9 7 -13 14 -14 35 -6 47 18 32 -12 -22 -44 -27 -17 -47 -34
-44 -38 3 -4 16 -1 30 8 23 16 25 16 21 -2 -2 -12 -12 -18 -33 -18 -23 -1 -33
-7 -43 -28 -7 -15 -17 -27 -23 -27 -5 0 -9 -17 -10 -37 0 -37 0 -38 -16 -16
-19 28 -29 29 -36 4 -3 -13 -17 -21 -45 -26 -23 -4 -46 -4 -51 -1 -5 3 -11 2
-13 -4 -1 -5 -13 -7 -25 -4 -15 4 -20 11 -15 19 10 19 2 115 -10 115 -5 0 -8
-15 -6 -32 3 -30 0 -34 -29 -43 -18 -5 -33 -13 -33 -17 0 -4 -9 -8 -20 -8 -11
0 -20 -4 -20 -10 0 -5 7 -10 15 -10 8 0 22 -8 32 -18 24 -24 87 -27 144 -8 71
25 143 19 132 -11 -4 -10 0 -14 12 -11 10 2 20 -3 22 -9 3 -7 -2 -13 -11 -13
-10 0 -13 -6 -10 -15 4 -8 2 -15 -3 -15 -6 0 -15 -12 -22 -26 -9 -22 -17 -25
-41 -22 -17 3 -30 2 -30 -2 0 -10 -88 -12 -94 -2 -3 6 8 24 26 41 20 20 26 31
17 31 -8 0 -20 -5 -27 -12 -6 -6 -15 -8 -19 -4 -4 4 -1 11 7 17 11 7 6 9 -20
7 -24 -2 -34 -7 -32 -17 3 -12 0 -12 -12 -1 -13 10 -16 10 -16 -1 0 -7 -10
-23 -22 -36 l-21 -23 21 0 c12 0 22 5 22 12 0 6 2 9 5 6 14 -13 -9 -38 -35
-38 -16 0 -32 -4 -35 -10 -3 -5 -15 -10 -26 -10 -16 0 -19 -4 -13 -22 3 -13 3
-19 -2 -15 -5 5 -10 43 -11 85 -5 127 -8 155 -21 160 -9 3 -11 -7 -7 -35 8
-59 -5 -153 -21 -153 -8 0 -14 -7 -14 -15 0 -16 -12 -20 -24 -8 -4 4 -19 9
-34 10 -15 2 -32 11 -39 19 -7 9 -17 13 -24 8 -8 -5 -11 -2 -6 8 4 12 1 14
-14 11 -22 -6 -70 11 -118 43 -19 13 -38 21 -43 18 -4 -3 -8 0 -8 5 0 6 -7 11
-15 11 -8 0 -15 7 -15 15 0 8 -4 15 -9 15 -5 0 -14 14 -20 30 -6 18 -17 30
-28 30 -11 0 -14 -3 -7 -8 12 -8 26 -52 16 -52 -18 1 -42 29 -42 49 0 11 -4
21 -10 21 -13 0 -13 27 0 32 6 2 13 37 16 77 3 41 9 82 15 92 5 10 9 34 9 53
0 25 6 40 20 48 25 16 25 30 2 51 -14 13 -25 15 -49 8 -17 -5 -34 -7 -36 -4
-3 3 -14 0 -24 -6 -14 -8 -4 -10 44 -7 33 3 64 2 67 -2 4 -3 -5 -15 -20 -27
-14 -11 -29 -36 -34 -55 l-8 -35 -1 40 c-1 28 -4 36 -10 25 -6 -8 -7 -18 -5
-22 3 -4 1 -8 -4 -8 -9 0 -42 -120 -42 -154 0 -18 1 -18 18 -3 10 10 22 14 25
10 14 -13 7 -43 -12 -53 -11 -5 -23 -20 -27 -32 l-8 -23 -5 23 c-3 13 -10 23
-14 22 -4 0 -15 6 -23 15 -18 18 -11 50 8 39 7 -4 4 2 -6 14 -23 26 -40 29
-34 5 4 -13 -1 -18 -18 -18 -12 0 -20 3 -17 8 2 4 -3 7 -11 7 -9 0 -16 -5 -16
-11 0 -6 -12 -9 -27 -7 -19 2 -29 10 -31 23 -3 17 -1 17 7 5 12 -17 14 -10 5
14 -5 13 -9 14 -20 5 -11 -9 -22 -7 -49 5 -19 9 -35 21 -35 26 0 6 -7 10 -15
10 -18 0 -19 5 -4 32 12 22 13 22 -34 3 -19 -8 -91 60 -84 79 4 10 1 13 -9 9
-21 -8 -18 18 8 66 23 43 61 59 51 21 -3 -11 -10 -20 -15 -20 -5 0 -7 -4 -3
-9 3 -5 0 -13 -5 -16 -7 -5 -5 -11 6 -19 16 -12 16 -13 -2 -21 -16 -7 -15 -9
11 -14 35 -8 59 16 59 59 1 20 7 26 29 28 16 2 29 -2 32 -11 4 -9 16 -12 36
-9 22 3 27 7 18 13 -9 6 0 9 27 9 37 0 43 4 54 30 8 19 28 38 56 52 24 12 44
25 44 29 0 13 55 27 110 28 40 1 51 -1 40 -9 -8 -5 -26 -10 -40 -10 -31 -1
-75 -37 -83 -67 -5 -24 8 -32 16 -10 3 9 7 9 17 0 10 -9 8 -14 -9 -25 -11 -7
-21 -16 -21 -20 0 -16 18 -7 53 26 20 18 43 36 52 39 8 4 20 17 25 29 5 13 9
17 9 10 1 -7 -6 -29 -14 -49 -18 -43 -11 -75 15 -71 11 2 25 -6 33 -16 13 -18
14 -17 10 10 -2 17 6 47 20 74 21 39 28 45 53 42 16 -2 32 -8 35 -14 3 -5 9
-2 13 8 5 12 1 23 -11 33 -17 13 -17 13 0 8 12 -5 17 -1 17 13 0 27 46 28 53
1z m-650 -6 c4 -8 2 -17 -3 -20 -6 -4 -10 -4 -10 -1 0 2 -3 11 -6 20 -3 9 -2
16 4 16 5 0 12 -7 15 -15z m5263 8 c16 -6 16 -7 -2 -14 -16 -6 -14 -8 9 -8 20
-1 26 -5 21 -13 -6 -9 -3 -9 9 0 9 8 22 12 28 10 13 -4 -91 -88 -109 -88 -7 0
-12 -6 -13 -12 0 -7 -4 -4 -8 7 -11 26 -4 43 21 51 17 5 18 8 7 16 -10 7 -11
11 -3 14 7 3 10 14 7 24 -6 21 4 25 33 13z m-5366 -7 c0 -14 -14 -36 -22 -36
-6 0 -7 7 -4 16 4 12 2 15 -9 10 -8 -3 -15 -1 -15 4 0 6 11 10 25 10 14 0 25
-2 25 -4z m80 -36 c0 -5 -7 -10 -16 -10 -8 0 -12 5 -9 10 3 6 10 10 16 10 5 0
9 -4 9 -10z m-130 -9 c0 -15 -19 -23 -38 -16 -14 5 -14 7 -2 15 19 12 40 13
40 1z m4835 -1 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4
11 -10z m-4945 -21 c7 -11 10 -21 8 -22 -2 -1 -13 -7 -25 -14 -17 -10 -24 -9
-33 2 -7 8 -19 15 -26 15 -24 0 -15 18 14 28 41 15 50 14 62 -9z m180 -13 c0
-9 -30 -14 -35 -6 -4 6 3 10 14 10 12 0 21 -2 21 -4z m4793 -10 c6 -15 -13
-26 -41 -26 -13 1 -11 4 6 13 12 7 19 16 16 20 -4 4 -3 7 3 7 6 0 13 -6 16
-14z m-83 -6 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10
-4 10 -10z m315 -3 c-10 -7 -33 -27 -49 -44 -28 -31 -56 -43 -56 -24 0 5 -11
9 -24 9 -22 0 -56 22 -56 35 0 3 15 7 33 10 17 3 45 9 61 13 19 4 27 3 23 -4
-4 -5 0 -12 8 -16 8 -3 15 -1 15 3 0 5 12 14 28 19 36 14 41 13 17 -1z m-5268
-3 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m5420 -21 c0 -14 -2
-21 -6 -14 -4 6 -15 9 -24 5 -20 -7 -22 1 -4 21 19 22 32 18 34 -12z m33 6 c0
-11 -4 -17 -10 -14 -5 3 -10 13 -10 21 0 8 5 14 10 14 6 0 10 -9 10 -21z
m-5180 -20 c0 -11 -4 -18 -10 -14 -5 3 -7 12 -3 20 7 21 13 19 13 -6z m4760
-9 c0 -5 6 -10 14 -10 8 0 22 -10 30 -22 18 -25 9 -41 -31 -52 -16 -5 -25 -1
-34 16 -15 28 -49 57 -69 58 -10 1 -9 4 5 10 28 12 85 12 85 0z m-4900 -14 c0
-7 -9 -16 -20 -19 -22 -6 -23 -5 -14 17 8 20 34 21 34 2z m5244 -7 c-3 -5 -14
-7 -24 -4 -12 4 -19 -1 -23 -16 -7 -25 -27 -17 -27 11 0 14 9 20 33 22 38 4
50 0 41 -13z m-233 -49 c5 -12 2 -16 -12 -17 -20 -2 -24 13 -12 43 5 14 8 15
11 3 3 -7 8 -21 13 -29z m-4881 14 c0 -8 -5 -12 -10 -9 -6 4 -8 11 -5 16 9 14
15 11 15 -7z m5004 -8 c-10 -8 -16 -18 -12 -22 5 -4 17 1 28 11 11 10 20 13
20 8 0 -6 9 -13 20 -16 13 -3 21 1 25 14 7 21 20 25 31 7 4 -6 -4 -20 -18 -32
-60 -50 -69 -56 -77 -56 -5 0 -14 -7 -21 -15 -7 -8 -27 -17 -45 -21 -22 -4
-32 -10 -29 -19 6 -15 -26 -29 -78 -33 -10 -1 -18 -9 -18 -17 0 -8 -4 -15 -10
-15 -5 0 -10 5 -10 11 0 6 -7 17 -15 26 -21 21 -14 37 18 41 23 3 28 9 33 43
6 38 8 39 46 39 22 0 48 -5 59 -10 15 -8 23 -7 35 5 14 13 13 15 -10 15 -14 0
-26 5 -26 10 0 6 7 10 15 10 9 0 18 7 21 15 4 8 13 15 22 15 13 0 12 -3 -4
-14z m-954 -31 c0 -10 -10 -15 -27 -15 -26 0 -26 1 -7 15 25 19 34 19 34 0z
m770 -12 c-8 -3 -19 -12 -23 -19 -12 -21 -37 -17 -37 6 0 17 6 20 38 19 20 0
30 -3 22 -6z m-920 -61 c0 -8 -43 -32 -55 -32 -14 0 -2 18 18 28 28 13 37 14
37 4z m860 -32 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11
-4 11 -10z m-4350 -27 c0 -19 -3 -24 -10 -17 -6 6 -8 18 -4 27 9 24 14 21 14
-10z m4235 -8 l-30 -25 30 5 c36 6 17 -4 -58 -30 -27 -9 -57 -26 -67 -38 -11
-11 -23 -17 -27 -14 -3 4 -14 -5 -24 -20 -14 -21 -15 -31 -7 -47 8 -14 8 -21
0 -24 -6 -2 -8 -10 -5 -18 6 -16 -22 -20 -32 -4 -4 6 -16 8 -28 4 -17 -5 -14
-1 11 20 20 17 32 37 32 52 0 13 5 24 11 24 5 0 8 4 4 10 -3 5 -1 17 4 27 5
10 12 25 14 34 3 9 31 23 74 34 37 10 75 22 83 26 34 19 44 8 15 -16z m-3190
9 c15 -6 15 -8 0 -24 -9 -10 -24 -16 -36 -13 -22 6 -26 23 -4 23 8 0 15 5 15
10 0 12 3 12 25 4z m3285 -25 c0 -4 -6 -9 -13 -12 -10 -4 -13 2 -9 20 3 18 7
21 13 11 5 -7 9 -16 9 -19z m-44 -44 c-11 -8 -25 -15 -30 -15 -6 0 -2 7 8 15
11 8 25 15 30 15 6 0 2 -7 -8 -15z m-4413 -27 c11 -14 17 -32 14 -39 -3 -9 -9
-4 -16 14 -6 17 -18 27 -31 27 -20 0 -26 11 -13 24 11 10 25 2 46 -26z m4358
-21 c-10 -9 -11 -8 -5 6 3 10 9 15 12 12 3 -3 0 -11 -7 -18z m109 13 c0 -5 -9
-10 -21 -10 -11 0 -34 -9 -51 -21 -39 -28 -49 -16 -12 16 29 25 84 35 84 15z
m-4360 -24 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2
10 -4z m4030 -10 c0 -8 -7 -17 -16 -20 -25 -10 -30 2 -8 19 24 18 24 18 24 1z
m-3023 -22 c-9 -9 -28 6 -21 18 4 6 10 6 17 -1 6 -6 8 -13 4 -17z m3162 -16
c1 -5 -6 -8 -14 -8 -15 0 -21 31 -8 44 7 6 20 -17 22 -36z m-2529 16 c0 -8 -4
-12 -10 -9 -5 3 -10 10 -10 16 0 5 5 9 10 9 6 0 10 -7 10 -16z m2547 -16 c-3
-7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m-3527 -8 c0 -18 -3 -19 -21
-9 -17 9 -19 13 -8 20 22 14 29 11 29 -11z m3467 -12 c-3 -8 -6 -5 -6 6 -1 11
2 17 5 13 3 -3 4 -12 1 -19z m-297 2 c0 -5 -7 -10 -16 -10 -8 0 -12 5 -9 10 3
6 10 10 16 10 5 0 9 -4 9 -10z m270 -5 c0 -8 -4 -15 -10 -15 -5 0 -7 7 -4 15
4 8 8 15 10 15 2 0 4 -7 4 -15z m-2070 -19 c0 -9 -30 -14 -35 -6 -4 6 3 10 14
10 12 0 21 -2 21 -4z m352 -28 c-7 -7 -12 -8 -12 -2 0 14 12 26 19 19 2 -3 -1
-11 -7 -17z m1632 -13 c9 -25 -55 -48 -69 -25 -10 16 14 40 40 40 13 0 25 -7
29 -15z m-84 -40 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0
10 -7 10 -15z m-1920 -29 c0 -2 2 -10 5 -18 4 -9 -7 -14 -37 -16 -41 -3 -42
-3 -23 18 15 17 55 29 55 16z m-1243 -46 l28 -1 -24 -19 c-25 -22 -21 -26 10
-9 11 5 39 13 62 17 38 7 44 5 65 -19 12 -15 22 -31 22 -35 0 -5 -4 -2 -10 6
-5 8 -12 12 -15 8 -5 -5 -13 -57 -14 -105 -1 -14 -64 -6 -69 9 -2 4 -9 5 -15
1 -8 -4 -7 -1 1 9 7 9 26 18 43 22 17 3 28 10 24 16 -7 12 -63 2 -92 -17 -23
-15 -32 -14 -133 22 -43 16 -50 33 -13 37 21 2 29 9 31 28 2 23 1 24 -11 8
-20 -26 -27 -22 -14 7 8 17 18 24 29 21 10 -2 21 0 24 5 3 5 13 5 24 -1 10 -5
31 -10 47 -10z m63 6 c0 -2 -7 -7 -16 -10 -8 -3 -12 -2 -9 4 6 10 25 14 25 6z
m110 -95 c0 -6 -4 -13 -10 -16 -5 -3 -10 1 -10 9 0 9 5 16 10 16 6 0 10 -4 10
-9z m-6 -73 c-9 -14 -23 2 -17 18 4 12 8 13 14 3 4 -7 5 -17 3 -21z m57 2 c18
0 9 -28 -11 -33 -11 -3 -20 -1 -20 3 0 5 -3 16 -7 26 -5 14 -3 16 11 11 10 -4
22 -7 27 -7z m1359 -114 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10
11 6 0 10 -2 10 -4z"/>
<path d="M6829 8667 c-82 -55 -103 -146 -52 -225 32 -49 74 -72 133 -72 120 0
199 123 147 230 -39 81 -156 116 -228 67z"/>
<path d="M2929 8310 c-89 -47 -112 -175 -45 -248 82 -91 225 -69 271 41 24 59
19 108 -16 156 -50 70 -136 90 -210 51z"/>
<path d="M8292 7930 c-35 -22 -68 -69 -77 -112 -11 -49 22 -125 69 -160 37
-27 47 -29 99 -26 64 5 98 25 135 78 47 70 13 183 -70 226 -40 21 -117 18
-156 -6z"/>
<path d="M9063 7304 c-24 -20 -43 -40 -43 -46 0 -10 133 -170 168 -202 25 -22
77 -16 98 12 28 37 16 65 -72 170 -47 56 -91 102 -98 102 -6 0 -30 -16 -53
-36z"/>
<path d="M9344 6877 c-8 -21 145 -255 167 -256 22 -1 49 62 49 113 0 48 -27
96 -72 129 -36 27 -135 36 -144 14z"/>
<path d="M4670 6320 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M4612 5637 c-74 -173 -186 -466 -180 -473 14 -14 359 -67 369 -56 4
4 22 81 40 172 17 91 38 188 45 215 29 114 45 188 40 193 -3 3 -50 11 -103 18
-54 7 -114 16 -134 19 l-37 5 -40 -93z"/>
<path d="M5208 5693 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5248 5693 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M5290 5540 c0 -22 5 -40 10 -40 6 0 10 18 10 40 0 22 -4 40 -10 40
-5 0 -10 -18 -10 -40z"/>
<path d="M4095 5220 c11 -5 29 -8 40 -8 16 0 15 2 -5 8 -34 11 -60 11 -35 0z"/>
<path d="M4235 5180 c-3 -6 1 -7 9 -4 18 7 21 14 7 14 -6 0 -13 -4 -16 -10z"/>
<path d="M4858 5063 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4811 4998 c0 -14 -6 -28 -13 -30 -7 -3 -9 -14 -3 -34 8 -29 8 -28
17 16 4 25 6 52 4 60 -2 8 -4 3 -5 -12z"/>
<path d="M9080 6040 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M8968 5973 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M8888 5904 c3 -15 8 -22 14 -16 9 9 1 42 -12 42 -4 0 -5 -12 -2 -26z"/>
<path d="M9180 5870 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M9132 5833 c-10 -4 -9 -8 3 -17 17 -12 35 -104 19 -93 -6 3 -16 1
-24 -4 -13 -8 -13 -10 0 -18 8 -5 17 -8 21 -6 4 3 9 -9 13 -26 9 -48 7 -117
-4 -124 -14 -8 -13 -42 2 -51 6 -4 8 -3 4 4 -3 6 -2 22 4 37 14 38 15 238 1
243 -6 2 -11 14 -11 27 0 26 -8 35 -28 28z"/>
<path d="M9060 5758 c0 -5 -8 -8 -17 -8 -11 0 -5 -7 15 -17 36 -17 37 -17 46
13 6 19 3 22 -19 20 -14 0 -25 -4 -25 -8z"/>
<path d="M8998 5753 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M9000 5716 c0 -9 7 -16 16 -16 17 0 14 22 -4 28 -7 2 -12 -3 -12 -12z"/>
<path d="M9137 5667 c-3 -9 1 -18 9 -21 16 -6 18 2 5 22 -7 11 -9 11 -14 -1z"/>
<path d="M9310 5670 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"/>
<path d="M9283 5535 c0 -27 2 -38 4 -22 2 15 2 37 0 50 -2 12 -4 0 -4 -28z"/>
<path d="M9360 5531 c0 -6 5 -13 10 -16 6 -3 10 1 10 9 0 9 -4 16 -10 16 -5 0
-10 -4 -10 -9z"/>
<path d="M9060 5460 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M9240 5386 c0 -9 5 -16 10 -16 6 0 10 4 10 9 0 6 -4 13 -10 16 -5 3
-10 -1 -10 -9z"/>
<path d="M1783 4658 c2 -7 -2 -20 -10 -29 -12 -15 -11 -16 12 -11 18 3 24 9
21 19 -3 8 -6 19 -6 24 0 5 -5 9 -11 9 -6 0 -9 -6 -6 -12z"/>
<path d="M7732 4405 c-47 -40 -57 -74 -44 -162 12 -87 31 -120 87 -146 47 -23
97 -12 136 29 22 24 24 33 22 115 -1 72 -6 96 -23 124 -41 67 -124 86 -178 40z"/>
<path d="M1955 4310 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M10360 4290 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5
0 -10 -4 -10 -10z"/>
<path d="M10305 4160 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3
0 -8 -4 -11 -10z"/>
<path d="M6068 4206 c-42 -6 -80 -15 -84 -18 -8 -9 5 -98 15 -98 26 0 180 24
199 31 30 11 32 73 3 89 -23 12 -32 12 -133 -4z m117 -55 c3 -5 1 -12 -5 -16
-5 -3 -10 1 -10 9 0 18 6 21 15 7z"/>
<path d="M5533 4120 c-35 -21 -68 -68 -40 -57 11 4 14 2 10 -9 -3 -9 -12 -13
-21 -9 -13 5 -14 -5 -9 -75 10 -131 50 -183 138 -182 50 1 84 25 102 71 13 31
14 52 6 117 -11 88 -25 116 -72 144 -40 25 -73 25 -114 0z m69 -241 c-19 -17
-37 -28 -40 -24 -7 7 48 54 63 54 6 0 -5 -13 -23 -30z"/>
<path d="M8530 2446 c0 -9 5 -16 10 -16 6 0 10 4 10 9 0 6 -4 13 -10 16 -5 3
-10 -1 -10 -9z"/>
<path d="M8417 2118 c-4 -12 -1 -26 6 -30 14 -8 13 -10 21 25 8 34 -19 39 -27
5z"/>
<path d="M8436 2055 c4 -8 7 -15 9 -15 2 0 5 7 9 15 3 9 0 15 -9 15 -9 0 -12
-6 -9 -15z"/>
<path d="M8197 2034 c-10 -13 -14 -24 -9 -24 13 0 35 27 30 39 -1 5 -11 -2
-21 -15z"/>
<path d="M8292 2036 c-16 -36 -15 -41 4 -22 8 9 14 24 12 33 -2 13 -6 10 -16
-11z"/>
<path d="M8385 2050 l-30 -6 30 -6 c32 -7 39 -5 34 9 -2 5 -17 6 -34 3z"/>
<path d="M8338 2017 c-30 -12 -43 -33 -26 -43 6 -4 8 -3 5 4 -6 9 54 26 79 21
21 -4 25 2 10 16 -17 17 -29 17 -68 2z"/>
<path d="M8160 1971 c0 -25 6 -27 13 -6 4 8 2 17 -3 20 -6 4 -10 -3 -10 -14z"/>
<path d="M8251 1938 c-1 -16 -1 -31 -2 -35 0 -4 5 -9 13 -11 10 -3 10 -2 -1 6
-7 5 -9 11 -4 13 5 2 6 15 2 29 l-7 25 -1 -27z"/>
<path d="M8210 1900 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z"/>
<path d="M8154 1878 c-4 -7 -14 -24 -23 -38 l-15 -25 24 20 c20 17 23 18 17 3
-4 -12 -1 -18 8 -18 8 0 15 7 15 15 0 9 14 16 38 20 61 11 61 15 0 12 -55 -3
-78 6 -40 16 15 4 15 5 0 6 -9 0 -20 -5 -24 -11z"/>
<path d="M8500 1880 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M8490 1830 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M8176 1792 c-3 -6 -1 -14 5 -17 15 -10 25 3 12 16 -7 7 -13 7 -17 1z"/>
<path d="M8319 1720 c23 -19 41 -19 41 1 0 6 -4 8 -9 4 -5 -3 -12 -1 -16 5 -3
5 -14 10 -23 10 -14 -1 -13 -4 7 -20z"/>
<path d="M8266 1703 c-6 -14 8 -32 25 -33 7 0 9 -90 2 -90 -3 0 -11 8 -18 17
-11 15 -14 15 -26 2 -21 -22 -20 -47 2 -42 11 3 19 0 19 -6 0 -6 -7 -11 -16
-11 -8 0 -12 -4 -9 -10 3 -5 1 -10 -5 -10 -7 0 -5 -6 4 -15 19 -20 26 -19 26
3 0 9 8 31 19 47 25 41 38 145 19 156 -20 13 -35 11 -42 -8z"/>
<path d="M7656 1394 c-11 -7 -18 -16 -15 -18 2 -2 13 2 23 10 11 7 18 16 15
18 -2 2 -13 -2 -23 -10z"/>
<path d="M8134 1399 c-9 -16 2 -22 21 -11 15 8 16 10 1 15 -8 3 -18 2 -22 -4z"/>
<path d="M7560 1305 c-7 -9 -11 -17 -9 -20 3 -2 10 5 17 15 14 24 10 26 -8 5z"/>
<path d="M7515 1170 c-3 -6 1 -7 9 -4 18 7 21 14 7 14 -6 0 -13 -4 -16 -10z"/>
<path d="M7423 1140 c-36 -22 -56 -52 -47 -73 3 -8 0 -18 -7 -23 -8 -4 -10 -3
-5 4 4 7 1 12 -9 12 -15 0 -15 1 -1 16 25 25 19 30 -7 6 -13 -12 -27 -22 -31
-22 -4 0 -27 -15 -51 -32 -24 -18 -56 -41 -70 -51 -21 -15 -24 -22 -14 -33 6
-7 16 -11 23 -7 6 3 2 -4 -9 -16 -11 -13 -27 -27 -37 -32 -14 -7 -17 -5 -16
13 1 13 6 29 11 36 5 6 6 12 3 12 -11 0 -42 -34 -51 -55 -4 -11 -9 -15 -12 -9
-4 12 -50 7 -59 -7 -3 -5 3 -9 13 -9 22 -1 44 -18 60 -46 9 -17 8 -24 -4 -37
-15 -15 -14 -15 7 -10 20 5 22 3 16 -12 -4 -10 -2 -21 4 -25 6 -4 14 -32 17
-63 3 -32 10 -57 14 -57 12 0 11 47 -1 71 -6 10 -13 42 -16 71 -6 66 18 118
56 119 14 0 19 3 13 6 -15 5 -17 23 -4 23 5 0 31 22 58 50 33 35 53 48 66 44
12 -4 32 10 67 46 38 40 54 50 70 45 16 -5 19 -3 13 10 -3 10 0 21 8 26 12 8
12 11 -1 19 -21 14 -31 12 -67 -10z m-10 -35 c-3 -9 -8 -14 -10 -11 -3 3 -2 9
2 15 9 16 15 13 8 -4z m-133 -103 c0 -5 -12 -14 -27 -21 l-28 -11 24 20 c26
22 31 24 31 12z"/>
<path d="M7395 990 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M7110 974 c0 -8 5 -12 10 -9 6 4 8 11 5 16 -9 14 -15 11 -15 -7z"/>
<path d="M7400 935 c0 -9 6 -12 15 -9 8 4 12 10 9 15 -8 14 -24 10 -24 -6z"/>
<path d="M6960 879 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M7010 836 c0 -2 7 -9 15 -16 9 -7 15 -8 15 -2 0 5 -7 12 -15 16 -8 3
-15 4 -15 2z"/>
<path d="M7423 832 c-13 -2 -23 -8 -23 -13 0 -13 37 -11 45 2 9 14 5 16 -22
11z"/>
<path d="M7160 790 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M7465 779 c-4 -6 -13 -8 -21 -5 -7 3 -16 1 -19 -4 -7 -11 9 -13 43
-4 12 4 22 10 22 15 0 12 -17 11 -25 -2z"/>
<path d="M7003 763 c-3 -10 -15 -28 -26 -40 -11 -13 -18 -26 -14 -29 7 -7 37
20 37 34 0 5 11 14 25 20 14 6 25 16 25 22 0 17 -40 11 -47 -7z"/>
<path d="M6780 743 c-68 -24 -188 -87 -188 -98 -1 -5 9 -9 21 -7 16 1 18 0 9
-7 -8 -4 -13 -12 -12 -17 1 -5 -3 -10 -9 -12 -6 -2 -11 1 -11 7 0 6 -5 11 -11
11 -8 0 -8 -4 0 -14 8 -10 8 -21 1 -41 -6 -15 -9 -30 -7 -32 9 -9 37 11 37 26
0 9 26 44 58 77 32 34 56 66 54 72 -5 15 10 16 25 1 12 -12 45 -9 43 5 -1 5
11 17 27 27 34 23 23 23 -37 2z"/>
<path d="M6678 743 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M6917 733 c-4 -3 -7 -17 -7 -30 0 -12 -5 -23 -11 -23 -6 0 -8 9 -4
23 5 19 5 20 -5 4 -6 -9 -15 -15 -21 -12 -5 4 -9 -3 -9 -15 0 -13 10 -24 26
-31 31 -11 34 -9 39 23 2 12 7 33 10 46 7 21 -4 30 -18 15z"/>
<path d="M6767 663 c-4 -3 -7 -14 -7 -24 0 -19 -29 -33 -52 -24 -9 4 -19 -4
-26 -20 -7 -14 -8 -25 -2 -25 13 0 1 -31 -22 -56 -13 -15 -16 -24 -9 -29 14
-8 71 3 89 17 21 18 13 30 -10 14 -36 -25 -51 -21 -32 10 9 14 23 23 30 20 10
-4 14 3 14 19 0 42 66 57 106 24 16 -13 17 -11 10 31 -4 24 -9 46 -11 48 -8 8
-25 -10 -25 -28 0 -13 -5 -17 -20 -13 -15 4 -18 9 -10 18 14 17 -7 35 -23 18z"/>
<path d="M7110 661 c0 -6 4 -13 10 -16 6 -3 7 1 4 9 -7 18 -14 21 -14 7z"/>
<path d="M6875 620 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0 -7
-4 -4 -10z"/>
<path d="M7210 620 c8 -5 22 -9 30 -9 10 0 8 3 -5 9 -27 12 -43 12 -25 0z"/>
<path d="M4447 594 c2 -7 0 -21 -6 -32 -9 -16 -7 -21 8 -25 14 -3 17 0 14 13
-3 11 5 25 19 35 15 11 17 15 6 12 -10 -4 -18 -3 -18 2 0 4 -6 8 -14 8 -8 0
-12 -6 -9 -13z"/>
<path d="M4610 581 c0 -5 7 -22 15 -37 l15 -29 -63 -6 c-37 -3 -48 -6 -27 -7
49 -3 81 -13 74 -23 -10 -17 25 -21 56 -8 38 18 38 29 0 29 -24 0 -31 5 -36
28 -11 52 -15 62 -25 62 -5 0 -9 -4 -9 -9z"/>
<path d="M6820 576 c0 -3 9 -10 20 -16 11 -6 20 -8 20 -6 0 3 -9 10 -20 16
-11 6 -20 8 -20 6z"/>
<path d="M6366 559 c4 -8 0 -9 -15 -4 -12 4 -21 2 -21 -4 0 -6 9 -11 20 -11
11 0 20 -4 20 -10 0 -6 -32 -10 -75 -10 -58 0 -77 -4 -81 -15 -4 -8 -13 -15
-21 -15 -8 0 -11 5 -8 10 3 6 -2 10 -12 10 -12 0 -19 -7 -19 -19 0 -11 4 -17
8 -15 4 3 8 0 8 -6 0 -6 -10 -15 -22 -21 l-23 -10 23 5 c12 2 22 -1 22 -7 0
-6 15 6 33 26 30 34 35 36 100 38 77 2 104 17 87 50 -6 10 -15 19 -21 19 -6 0
-7 -5 -3 -11z"/>
<path d="M4525 550 c3 -5 13 -10 21 -10 8 0 14 5 14 10 0 6 -9 10 -21 10 -11
0 -17 -4 -14 -10z"/>
<path d="M6400 550 c0 -5 16 -10 36 -10 21 0 33 4 29 10 -3 6 -19 10 -36 10
-16 0 -29 -4 -29 -10z"/>
<path d="M6290 540 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z"/>
<path d="M4913 515 c-18 -20 -17 -49 2 -41 10 4 15 -1 15 -15 0 -19 1 -19 16
-5 8 9 27 16 42 17 l27 0 -23 12 c-19 8 -25 8 -32 -4 -7 -10 -11 -6 -16 19 -7
35 -13 38 -31 17z"/>
<path d="M6415 520 c-14 -6 3 -9 53 -9 42 -1 72 3 72 9 0 12 -97 12 -125 0z"/>
<path d="M6800 504 c0 -9 -9 -27 -20 -41 -24 -31 -18 -44 8 -20 10 10 21 15
23 12 3 -2 3 11 1 30 -4 36 -12 48 -12 19z"/>
<path d="M4736 503 c-11 -11 -6 -23 9 -23 8 0 15 -4 15 -9 0 -9 30 -24 36 -18
2 1 -10 15 -25 30 -16 14 -31 23 -35 20z"/>
<path d="M6557 503 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
-9 2 -12 -2z"/>
<path d="M4877 481 c-4 -17 -3 -21 5 -13 5 5 8 16 6 23 -3 8 -7 3 -11 -10z"/>
<path d="M5057 485 c6 -17 25 -15 31 3 2 7 -5 12 -17 12 -14 0 -19 -5 -14 -15z"/>
<path d="M6765 490 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M5288 463 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M6080 460 c0 -13 11 -13 30 0 12 8 11 10 -7 10 -13 0 -23 -4 -23 -10z"/>
<path d="M6663 458 c2 -7 -1 -20 -9 -28 -10 -12 -10 -18 2 -34 12 -17 14 -18
14 -3 0 10 8 17 20 17 23 0 24 4 9 33 -12 22 -12 22 9 3 11 -10 27 -16 34 -13
11 4 11 6 0 6 -7 1 -20 8 -28 16 -19 18 -58 20 -51 3z"/>
<path d="M7103 463 c-17 -6 -16 -20 1 -26 8 -3 16 2 19 14 5 19 2 21 -20 12z"/>
<path d="M5507 453 c-4 -3 -7 -13 -7 -20 0 -16 32 -10 65 13 18 13 17 14 -16
14 -20 0 -39 -3 -42 -7z"/>
<path d="M5905 450 c4 -6 11 -8 16 -5 14 9 11 15 -7 15 -8 0 -12 -5 -9 -10z"/>
<path d="M6600 449 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M5789 408 c-3 -22 -1 -34 4 -29 5 5 7 21 5 37 -3 27 -4 26 -9 -8z"/>
<path d="M6028 433 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M4602 395 c3 -14 1 -25 -6 -25 -6 0 -4 -5 4 -10 21 -14 33 -12 27 3
-3 8 3 20 14 28 27 19 23 29 -12 29 -28 0 -31 -3 -27 -25z"/>
<path d="M6170 401 c0 -12 5 -21 10 -21 6 0 10 6 10 14 0 8 -4 18 -10 21 -5 3
-10 -3 -10 -14z"/>
<path d="M6740 410 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M6805 410 c-3 -5 1 -10 10 -10 9 0 13 5 10 10 -3 6 -8 10 -10 10 -2
0 -7 -4 -10 -10z"/>
<path d="M4264 375 c-3 -20 -4 -39 0 -42 9 -10 13 -1 19 40 6 47 -10 49 -19 2z"/>
<path d="M4840 397 c0 -17 -3 -18 -82 -21 -25 0 -44 -5 -41 -9 3 -5 31 -7 64
-5 47 2 60 6 65 21 3 10 3 21 0 24 -3 4 -6 -1 -6 -10z"/>
<path d="M5118 398 c9 -9 15 -9 24 0 9 9 7 12 -12 12 -19 0 -21 -3 -12 -12z"/>
<path d="M4948 393 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4987 393 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
-9 2 -12 -2z"/>
<path d="M5586 388 c-15 -8 -16 -12 -5 -19 10 -6 3 -9 -19 -9 -30 0 -33 -2
-26 -22 3 -12 16 -29 27 -38 14 -12 17 -19 9 -24 -7 -4 -12 -2 -12 3 0 6 -4
11 -8 11 -5 0 -9 -13 -9 -30 0 -16 3 -30 7 -30 13 0 50 47 50 63 0 9 5 19 10
22 9 5 7 71 -2 80 -2 2 -12 -1 -22 -7z"/>
<path d="M4520 371 c0 -5 9 -7 20 -4 11 3 20 7 20 9 0 2 -9 4 -20 4 -11 0 -20
-4 -20 -9z"/>
<path d="M6180 336 c0 -20 5 -36 10 -36 6 0 10 13 10 29 0 17 -4 33 -10 36 -6
4 -10 -8 -10 -29z"/>
<path d="M5320 344 c0 -8 5 -12 10 -9 6 4 8 11 5 16 -9 14 -15 11 -15 -7z"/>
<path d="M4680 330 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M4810 330 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M6348 315 c-3 -16 -1 -25 7 -25 8 0 10 9 7 25 -2 14 -6 25 -7 25 -1
0 -5 -11 -7 -25z"/>
<path d="M6720 330 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M4710 312 c0 -10 38 -42 50 -42 14 0 12 7 -7 30 -17 20 -43 27 -43
12z"/>
<path d="M5250 305 c-19 -7 -28 -14 -20 -14 8 0 29 6 45 14 39 17 21 18 -25 0z"/>
<path d="M4455 300 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0 -7
-4 -4 -10z"/>
<path d="M4790 299 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M5916 264 c-4 -20 -5 -38 -2 -41 2 -3 8 11 12 31 3 21 4 39 1 42 -2
3 -8 -11 -11 -32z"/>
<path d="M4610 283 c-8 -4 -20 -12 -27 -18 -12 -12 -49 -9 -126 10 -20 5 -27
3 -27 -7 0 -10 25 -20 78 -31 42 -9 85 -17 95 -17 9 0 17 -4 17 -10 0 -5 -4
-10 -10 -10 -5 0 -10 -7 -10 -16 0 -14 3 -14 15 -4 13 11 15 8 15 -13 0 -14
-5 -27 -12 -29 -9 -3 -9 -6 0 -12 8 -5 17 4 27 27 13 32 43 51 73 48 19 -3 14
27 -5 32 -17 4 -17 5 0 6 10 1 17 9 17 22 0 12 -4 18 -11 13 -6 -3 -15 0 -20
7 -7 12 -12 12 -31 0 -16 -10 -23 -10 -26 -2 -4 12 -9 13 -32 4z m80 -23 c0
-17 -28 -23 -57 -11 l-28 11 27 -5 c15 -3 30 -1 33 5 8 13 25 13 25 0z"/>
<path d="M4846 273 c-3 -3 -3 -10 0 -14 7 -12 34 -11 34 0 0 11 -26 22 -34 14z"/>
<path d="M4780 254 c0 -8 5 -12 10 -9 6 4 8 11 5 16 -9 14 -15 11 -15 -7z"/>
<path d="M4802 230 c-28 -30 -28 -36 0 -44 29 -7 36 2 29 39 l-6 29 -23 -24z
m18 -14 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10
-4z"/>
<path d="M5580 200 c-8 -16 -7 -26 5 -42 15 -22 15 -21 15 20 0 47 -4 51 -20
22z"/>
<path d="M4696 155 c4 -8 10 -15 15 -15 5 0 9 7 9 15 0 8 -7 15 -15 15 -9 0
-12 -6 -9 -15z"/>
<path d="M5426 145 c-3 -9 0 -15 9 -15 9 0 12 6 9 15 -4 8 -7 15 -9 15 -2 0
-5 -7 -9 -15z"/>
<path d="M5245 100 c-13 -6 -15 -9 -5 -9 8 0 22 4 30 9 18 12 2 12 -25 0z"/>
<path d="M1788 3103 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2606 1265 c-3 -9 0 -15 9 -15 8 0 15 7 15 15 0 8 -4 15 -9 15 -5 0
-11 -7 -15 -15z"/>
<path d="M2557 1143 c-4 -3 -7 -13 -7 -21 0 -12 3 -12 15 -2 8 7 15 16 15 21
0 11 -14 12 -23 2z"/>
<path d="M7715 1100 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"/>
<path d="M3498 1023 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M3177 965 c-14 -28 -21 -35 -35 -30 -9 5 -30 10 -47 12 l-30 4 30
-20 c17 -10 48 -23 69 -27 34 -6 38 -5 33 10 -4 10 -1 19 8 22 16 7 21 34 6
34 -5 0 -11 7 -13 16 -2 11 -9 4 -21 -21z"/>
<path d="M3479 996 c0 0 -2 -11 -5 -23 -5 -27 12 -31 19 -5 4 16 5 16 6 0 0
-10 6 -18 12 -18 5 0 8 -4 5 -8 -3 -5 1 -9 8 -9 9 0 11 9 6 31 -4 23 -12 31
-28 32 -12 1 -22 1 -23 0z"/>
<path d="M3723 980 c-2 -19 8 -26 20 -14 7 7 -2 34 -12 34 -4 0 -7 -9 -8 -20z"/>
<path d="M2935 920 c20 -23 29 -21 19 4 -3 9 -13 16 -22 16 -13 0 -12 -3 3
-20z"/>
<path d="M3280 915 c0 -9 5 -15 11 -13 6 2 11 8 11 13 0 5 -5 11 -11 13 -6 2
-11 -4 -11 -13z"/>
<path d="M3490 905 c7 -8 17 -15 22 -15 5 0 3 7 -6 15 -8 8 -18 15 -22 15 -3
0 -1 -7 6 -15z"/>
<path d="M3380 900 c0 -5 4 -10 9 -10 6 0 13 5 16 10 3 6 -1 10 -9 10 -9 0
-16 -4 -16 -10z"/>
<path d="M3725 900 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"/>
<path d="M3032 870 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z"/>
<path d="M3120 896 c0 -11 30 -39 36 -34 2 3 -5 13 -16 23 -11 10 -20 15 -20
11z"/>
<path d="M3218 893 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M3420 878 c0 -7 13 -13 30 -14 24 -1 28 1 19 12 -14 17 -49 18 -49 2z"/>
<path d="M3060 844 c-13 -32 -12 -44 0 -22 6 9 18 14 29 11 29 -7 33 13 5 25
-22 11 -26 9 -34 -14z"/>
<path d="M4021 810 c-12 -36 1 -40 17 -5 14 31 15 35 3 35 -5 0 -14 -13 -20
-30z"/>
<path d="M3170 815 c0 -8 4 -15 10 -15 5 0 7 7 4 15 -4 8 -8 15 -10 15 -2 0
-4 -7 -4 -15z"/>
<path d="M3140 770 c0 -5 -10 -10 -22 -10 -21 -1 -21 -2 -6 -11 14 -7 23 -5
40 11 18 17 19 20 5 20 -9 0 -17 -4 -17 -10z"/>
<path d="M3883 743 c9 -2 17 -11 17 -19 0 -8 5 -14 10 -14 6 0 10 -8 11 -17 0
-10 3 -13 5 -7 3 6 0 23 -5 37 -8 19 -17 27 -33 26 -17 -1 -19 -2 -5 -6z"/>
<path d="M3700 710 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M3698 643 c-3 -22 0 -41 6 -45 7 -5 12 5 14 29 5 50 -14 65 -20 16z"/>
<path d="M3598 645 c-4 -17 -1 -25 8 -25 8 0 14 9 14 19 0 30 -17 34 -22 6z"/>
<path d="M3328 653 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M3645 650 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0 -7
-4 -4 -10z"/>
<path d="M4033 630 c2 -9 -8 -22 -25 -32 -15 -10 -28 -22 -28 -28 0 -15 11
-12 26 7 7 10 16 14 20 10 5 -4 2 -12 -6 -17 -12 -8 -12 -10 2 -15 9 -4 28 1
43 11 25 16 26 19 10 30 -9 7 -23 21 -31 31 -11 13 -14 14 -11 3z"/>
<path d="M3648 623 c-10 -3 -18 -12 -18 -20 0 -14 2 -14 19 1 18 17 19 17 24
-1 4 -15 5 -16 6 -1 1 20 -8 26 -31 21z"/>
<path d="M3300 611 c0 -6 5 -13 10 -16 6 -3 10 1 10 9 0 9 -4 16 -10 16 -5 0
-10 -4 -10 -9z"/>
<path d="M3600 596 c0 -9 5 -16 10 -16 6 0 10 4 10 9 0 6 -4 13 -10 16 -5 3
-10 -1 -10 -9z"/>
<path d="M3370 580 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M3391 551 c-12 -22 -5 -27 19 -11 11 7 12 12 3 21 -8 8 -14 6 -22
-10z"/>
<path d="M4050 490 c-13 -8 -13 -10 2 -10 9 0 20 5 23 10 8 13 -5 13 -25 0z"/>
<path d="M4104 447 c-8 -21 2 -35 16 -21 5 5 6 15 0 24 -8 12 -10 12 -16 -3z"/>
<path d="M3730 409 c0 -11 5 -17 10 -14 6 3 10 13 10 21 0 8 -4 14 -10 14 -5
0 -10 -9 -10 -21z"/>
<path d="M7770 824 c0 -8 -4 -13 -9 -10 -5 3 -11 -3 -14 -14 -3 -11 -1 -20 4
-20 5 0 9 5 9 11 0 8 4 8 14 0 11 -10 16 -8 21 8 7 22 0 41 -16 41 -5 0 -9 -7
-9 -16z"/>
<path d="M7361 664 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4140 340 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
</g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
